import { keysToCamel } from '~utils'
import {
  FETCH_CONTENT_OPS_BY_ID,
  UPDATE_CONTENT_OPS
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTENT_OPS_BY_ID:
      return {
        ...state,
        ...keysToCamel(action.payload)
      }
    case UPDATE_CONTENT_OPS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
