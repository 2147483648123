import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { fetchDataFieldById, resetUpdateDataFieldStatus, updateDataField } from '~actions'
import { DatafieldBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner, Switch } from '~stories'
import { getFileName } from '~utils'

import styles from './EditDataField.module.css'

const PAGE_TITLE = 'Edit Data Field'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditDataField = ({
  allVenues = [],
  fetchDataFieldById,
  resetUpdateDataFieldStatus,
  route,
  dataField,
  updateMessage,
  updateDataField,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [dataFieldId, setDataFieldId] = useState('')
  const [dataFieldToEdit, setDataFieldToEdit] = useState()
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    const dataFieldId = idx(route, _ => _.match.params.id)

    if (dataFieldId) {
      setIsDataLoaded(false)
      fetchDataFieldById(dataFieldId)
      setDataFieldId(dataFieldId)
    }
  }, [route, fetchDataFieldById])

  useEffect(() => {
    if (dataField) {
      setDataFieldToEdit(dataField)
      setIsDataLoaded(true)
    }
  }, [dataField])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdateDataFieldStatus()
    }
  }, [updateStatus, updateMessage, resetUpdateDataFieldStatus])

  const handleSubmit = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    const updatedDataFeild = {
      ...dataFieldToEdit
    }
    updateDataField(dataFieldId, updatedDataFeild, accessToken)
  }

  const addCTAInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        url: '',
        label: '',
        target: ''
      }
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addGalleryInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: []
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addMapInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: ''
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addNewGalleryInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    const { gallery = [] } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: [
        ...gallery,
        {
          title: '',
          src: '',
          alt: '',
          mediaType: '',
          subtitle: ''
        }
      ]
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules.splice(dataFieldIndex, 1)

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModuleCTA = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: null
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteAllGalleryFlexModule = (event, fieldIndex) => {
    event.stopPropagation()

    const clonedDataField = { ...dataFieldToEdit }

    if (fieldIndex !== -1) {
      if (clonedDataField.flex_modules[fieldIndex]) {
        clonedDataField.flex_modules[fieldIndex].gallery = null
      }
    }
    setDataFieldToEdit(clonedDataField)
  }

  const deleteFlexModuleGallery = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: []
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModuleMapEmbedUrl = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: null
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteGalleryFlexModule = (event, dataFieldGalleryIndex, fieldIndex) => {
    event.stopPropagation()

    const clonedDataField = { ...dataFieldToEdit }

    if (fieldIndex !== -1) {
      const { flex_modules: flexModules } = clonedDataField
      const dField = flexModules[fieldIndex]
      const { gallery = [] } = dField

      if (dataFieldGalleryIndex !== -1) {
        gallery.splice(dataFieldGalleryIndex, 1)
      }
      clonedDataField.flex_modules[fieldIndex].gallery = [
        ...gallery
      ]
    }
    setDataFieldToEdit(clonedDataField)
  }

  const handleAddFlexModule = (event) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules,
        {
          type: 'default',
          image: {
            url: '',
            alt: ''
          },
          title: '',
          description: '',
          gallery: null,
          cta: null,
          map_src_url: null
        }
      ]
    })
  }

  const handleCTALabelChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        label: event.target.value
      }
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleCTAUrlChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        url: event.target.value
      }
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleCTATargetChange = (selectedItems, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        target: selectedItems
      }
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleDescriptionChange = (description, index) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[index] = {
      ...flexModules[index],
      description
    }

    setDataFieldToEdit({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleImageUrlChange = (secureImageURL, index) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    const alt = getFileName(secureImageURL)

    flexModules[index] = {
      ...flexModules[index],
      image: {
        alt,
        url: secureImageURL
      }
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleTitleChange = (event, index) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[index] = {
      ...flexModules[index],
      title: event.target.value
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleDescriptionChange = description => {
    setDataFieldToEdit({
      ...dataFieldToEdit,
      description
    })
  }

  const handleFlexModuleGalleryMediaChange = (selectedItems, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        mediaType: selectedItems
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleTypeChange = (selectedType, index) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[index] = {
      ...flexModules[index],
      type: selectedType
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleGalleryImageAltChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        alt: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }
  const handleGalleryImageUrlUpdate = (imageUrl, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        src: imageUrl
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleGallerySubTitleChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        subtitle: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleGalleryTitleChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        title: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleMapEmbedChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataFieldToEdit

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: event.target.value
    }

    setDataFieldToEdit({
      ...dataFieldToEdit,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleTitleChange = (event) => {
    setDataFieldToEdit({
      ...dataFieldToEdit,
      title: event.target.value
    })
  }

  const handleVenueChange = (selectedVenue) => {
    setDataFieldToEdit({
      ...dataFieldToEdit,
      venue_id: selectedVenue
    })
  }

  const handleDataTypeChange = (selectedType) => {
    setDataFieldToEdit({
      ...dataFieldToEdit,
      type: selectedType
    })
  }

  const keepEditingDataField = (
    <Route key="editDataFields" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateDataFieldStatus()
          history.push(`/datafields/${dataFieldId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListDataFieldsButton = (
    <Route key="listDataFields" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateDataFieldStatus()
          history.push('/datafields')
        }}
        type="primary"
      >
        Go to List Page
      </Button>
    )} />
  )

  const updateDataFieldButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const handleModalClose = () => {
    resetUpdateDataFieldStatus()
  }

  const publishSwitch = <Switch
    checked={dataFieldToEdit?.is_default || false}
    isInline={true}
    label="Is Default"
    onChange={(status) => {
      setDataFieldToEdit({
        ...dataFieldToEdit,
        is_default: status
      })
    }}
  />

  return (
    <PageLayout extra={[publishSwitch, updateDataFieldButton]} title={PAGE_TITLE}>
      {dataFieldToEdit && <div className={styles.DataFieldsLayoutBackground}>
        <DatafieldBasicInfo
          addCTAInFlexModule={addCTAInFlexModule}
          addGalleryInFlexModule={addGalleryInFlexModule}
          addMapInFlexModule={addMapInFlexModule}
          addNewGalleryInFlexModule={addNewGalleryInFlexModule}
          dataField={dataFieldToEdit}
          deleteAllGalleryFlexModule={deleteAllGalleryFlexModule}
          deleteFlexModule={deleteFlexModule}
          deleteFlexModuleCTA={deleteFlexModuleCTA}
          deleteFlexModuleGallery={deleteFlexModuleGallery}
          deleteFlexModuleMapEmbedUrl={deleteFlexModuleMapEmbedUrl}
          deleteGalleryFlexModule={deleteGalleryFlexModule}
          handleAddFlexModule={handleAddFlexModule}
          handleCTALabelChange={handleCTALabelChange}
          handleCTATargetChange={handleCTATargetChange}
          handleCTAUrlChange={handleCTAUrlChange}
          handleDFDescriptionChange={handleFlexModuleDescriptionChange}
          handleDFImageUrlUpdate={handleFlexModuleImageUrlChange}
          handleDFTitleChange={handleFlexModuleTitleChange}
          handleDataTypeChange={handleDataTypeChange}
          handleDescriptionChange={handleDescriptionChange}
          handleFlexModuleGalleryMediaChange={handleFlexModuleGalleryMediaChange}
          handleFlexModuleTypeChange={handleFlexModuleTypeChange}
          handleGalleryImageAltChange={handleGalleryImageAltChange}
          handleGalleryImageUrlUpdate={handleGalleryImageUrlUpdate}
          handleGallerySubTitleChange={handleGallerySubTitleChange}
          handleGalleryTitleChange={handleGalleryTitleChange}
          handleMapEmbedChange={handleMapEmbedChange}
          handleTitleChange={handleTitleChange}
          handleVenueChange={handleVenueChange}
          venues={allVenues}
        />
      </div>}
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListDataFieldsButton,
            keepEditingDataField
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ dataFields }) => {
  return {
    dataField: dataFields.dataField,
    updateStatus: dataFields.success,
    updateMessage: dataFields.message,
    allVenues: dataFields.allVenues
  }
}

const mapDispatchToProps = {
  fetchDataFieldById,
  resetUpdateDataFieldStatus,
  updateDataField
}

EditDataField.propTypes = {
  allVenues: PropTypes.array.isRequired,
  dataField: PropTypes.object.isRequired,
  fetchDataFieldById: PropTypes.func,
  resetUpdateDataFieldStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateDataField: PropTypes.func,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.string
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDataField)
