import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Collapse, Input, Row, Space, Typography } from 'antd'
import PropTypes from 'prop-types'
import { RichTextContentEditor } from '~components'
import { MultiSelectDropdown } from '~stories'
import { DATA_FIELD_TYPE, FIELD_TYPE } from '../../constants'
import styles from './DatafieldBasicInfo.module.css'
import DefaultFlexModule from './DefaultType'

const { Panel } = Collapse

const { Title, Text } = Typography

const DatafieldBasicInfo = ({
  addCTAInFlexModule,
  addGalleryInFlexModule,
  addMapInFlexModule,
  addNewGalleryInFlexModule,
  dataField,
  deleteFlexModule,
  deleteFlexModuleGallery,
  handleCTALabelChange,
  deleteFlexModuleCTA,
  handleCTATargetChange,
  handleMapEmbedChange,
  deleteFlexModuleMapEmbedUrl,
  handleCTAUrlChange,
  deleteAllGalleryFlexModule,
  deleteGalleryFlexModule,
  handleFlexModuleGalleryMediaChange,
  handleGalleryImageAltChange,
  handleGalleryImageUrlUpdate,
  handleGallerySubTitleChange,
  handleGalleryTitleChange,
  handleAddFlexModule,
  handleDescriptionChange,
  handleDataTypeChange,
  handleDFDescriptionChange,
  handleDFImageUrlUpdate,
  handleDFTitleChange,
  handleFlexModuleTypeChange,
  handleTitleChange,
  handleVenueChange,
  venues
}) => {
  return (
    <Card style={{ border: '1px solid rgb(32, 32, 34)' }}>
      <Row gutter={16}>
        <Col span={18}>
          <Row gutter={16}><Title level={5}>Event Venue* </Title>&nbsp;<Text>(Select one item from dropdown)</Text></Row>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='name'
            dropdownOptions={venues}
            handleChange={handleVenueChange}
            selectedItems={dataField?.venue_id}
            valueField='_id'
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Row gutter={16}><Title level={5}>Data field type* </Title>&nbsp;<Text>(Select one item from dropdown)</Text></Row>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='label'
            dropdownOptions={DATA_FIELD_TYPE}
            handleChange={handleDataTypeChange}
            selectedItems={dataField?.type}
            valueField='label'
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>Title*</Title>&nbsp;<Text>(70 characters max)</Text></Row>
          <Input maxLength={70} onChange={handleTitleChange} value={dataField?.title} />
        </Col>
      </Row><br/>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>Description</Title>&nbsp;<Text /></Row>
          {/* <RichTextInput onChange={handleDescriptionChange} value={dataField?.description} /> */}
          <RichTextContentEditor onChange={handleDescriptionChange} value={dataField?.description} />
        </Col>
      </Row><br/>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            extra={
              dataField?.flex_modules &&
              dataField?.flex_modules.length > -1 &&
              <Button
                icon={<PlusOutlined />}
                onClick={(event) => handleAddFlexModule(event)}
                shape="circle"
              />
            }
            style={{ width: '100%' }}
            title="Flex Modules"
          ><Collapse accordion defaultActiveKey={['0']} expandIconPosition='right'>
              {dataField?.flex_modules.map((dField, dataFieldIndex) => (<Panel key={dataFieldIndex}
                extra={<Row gutter={2}><Space>
                  <Button
                    className={styles.primaryButton}
                    disabled={dField.cta}
                    onClick={(event) => addCTAInFlexModule(event, dataFieldIndex)}
                    type="primary">
                      Add CTA
                  </Button>
                  <Button
                    className={styles.primaryButton}
                    disabled={dField?.map_src_url !== null }
                    onClick={(event) => addMapInFlexModule(event, dataFieldIndex)}
                    type="primary">
                      Add Map
                  </Button>
                  <Button
                    className={styles.primaryButton}
                    disabled={dField?.gallery && dField?.gallery?.length > -1}
                    onClick={(event) => addGalleryInFlexModule(event, dataFieldIndex)}
                    type="primary">
                      Add Gallery
                  </Button>
                  <Button
                    className={styles.deleteButton}
                    danger
                    onClick={(event) => deleteFlexModule(event, dataFieldIndex)}
                    type="primary">
                      Delete
                  </Button>
                </Space>
                </Row>
                }
                header={<span style={{
                  fontSize: 20
                }}>{dField?.title}</span>}
              >
                <Row key={dataFieldIndex} style={{ marginTop: 24 }}>
                  <Row key={dataFieldIndex} style={{ width: '100%' }}>
                    <Col span={18}>
                      <Row><Title level={5}>Type* </Title>&nbsp;<Text>(Select the flex module type from dropdown)</Text></Row>
                      <MultiSelectDropdown
                        allowClear={true}
                        customStyle={{ width: 450 }}
                        displayField='label'
                        dropdownOptions={FIELD_TYPE}
                        handleChange={(selectedItems) => handleFlexModuleTypeChange(selectedItems, dataFieldIndex)}
                        selectedItems={dField?.type}
                        valueField='value'
                      />
                    </Col>
                  </Row><div style={{
                    display: 'block',
                    width: '100%'
                  }}>
                    <DefaultFlexModule
                      addNewGalleryInFlexModule={addNewGalleryInFlexModule}
                      dataField={dField}
                      deleteAllGalleryFlexModule={deleteAllGalleryFlexModule}
                      deleteFlexModuleCTA={deleteFlexModuleCTA}
                      deleteFlexModuleGallery={deleteFlexModuleGallery}
                      deleteFlexModuleMapEmbedUrl={deleteFlexModuleMapEmbedUrl}
                      deleteGalleryFlexModule={deleteGalleryFlexModule}
                      fieldIndex={dataFieldIndex}
                      handleCTALabelChange={handleCTALabelChange}
                      handleCTATargetChange={handleCTATargetChange}
                      handleCTAUrlChange={handleCTAUrlChange}
                      handleDescriptionChange={handleDFDescriptionChange}
                      handleFlexModuleGalleryMediaChange={handleFlexModuleGalleryMediaChange}
                      handleGalleryImageAltChange={handleGalleryImageAltChange}
                      handleGalleryImageUrlUpdate={handleGalleryImageUrlUpdate}
                      handleGallerySubTitleChange={handleGallerySubTitleChange}
                      handleGalleryTitleChange={handleGalleryTitleChange}
                      handleImageUrlUpdate={handleDFImageUrlUpdate}
                      handleMapEmbedChange={handleMapEmbedChange}
                      handleTitleChange={handleDFTitleChange}
                    />
                  </div>
                </Row></Panel>
              ))}</Collapse>
          </Card><br />
        </Col>
      </Row>
    </Card>
  )
}

DatafieldBasicInfo.propTypes = {
  addCTAInFlexModule: PropTypes.func,
  addGalleryInFlexModule: PropTypes.func,
  addMapInFlexModule: PropTypes.func,
  addNewGalleryInFlexModule: PropTypes.func,
  dataField: PropTypes.object,
  deleteAllGalleryFlexModule: PropTypes.func,
  deleteFlexModule: PropTypes.func,
  deleteFlexModuleCTA: PropTypes.func,
  deleteFlexModuleGallery: PropTypes.func,
  deleteFlexModuleMapEmbedUrl: PropTypes.func,
  deleteGalleryFlexModule: PropTypes.func,
  handleAddFlexModule: PropTypes.func,
  handleCTALabelChange: PropTypes.func,
  handleCTATargetChange: PropTypes.func,
  handleCTAUrlChange: PropTypes.func,
  handleDFDescriptionChange: PropTypes.func,
  handleDFImageUrlUpdate: PropTypes.func,
  handleDFTitleChange: PropTypes.func,
  handleDataTypeChange: PropTypes.func,
  handleDescriptionChange: PropTypes.func,
  handleFlexModuleGalleryMediaChange: PropTypes.func,
  handleFlexModuleTypeChange: PropTypes.func,
  handleGalleryImageAltChange: PropTypes.func,
  handleGalleryImageUrlUpdate: PropTypes.func,
  handleGallerySubTitleChange: PropTypes.func,
  handleGalleryTitleChange: PropTypes.func,
  handleMapEmbedChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  handleVenueChange: PropTypes.func,
  venues: PropTypes.array
}

DatafieldBasicInfo.defaultProps = {
  dataField: {},
  venues: []
}

export default DatafieldBasicInfo
