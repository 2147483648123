import React from 'react'
import { Card, Typography, Input, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import styles from './BuyBox.module.css'

const BuyBox = ({ buyGroupTicketUrl, buyTicketErr, buyTicketUrl, multiTicketUrl, setBuyGroupTicketUrl, setBuyTicketUrl, setMultiTicketUrl }) => {
  const { Title, Paragraph } = Typography

  return (
    <div>
      <Title className={styles['title-text']} level={2}>Buy Box & Call-to-Actions</Title>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Buy Tickets URL* </Title></Col>
          <Col><Title level={4}>(Appears for all events)</Title></Col>
        </Row>
        <Row >
          <Input
            onChange={setBuyTicketUrl}
            required
            status={buyTicketErr ? 'error' : ''}
            style={{ width: '100%' }}
            type="url"
            value= { buyTicketUrl }
          />
        </Row>
        {buyTicketErr && <Paragraph className={styles['paragraph-text']}>Please fill out this field</Paragraph>}
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Buy Multi-Event Tickets URL </Title></Col>
          <Col><Title level={4}>(Only appears for select multi-day/time events)</Title></Col>
        </Row>
        <Row >
          <Input
            onChange={setMultiTicketUrl}
            required
            style={{ width: '100%' }}
            type="url"
            value= { multiTicketUrl }
          />
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Buy Group Tickets URL</Title></Col>
          <Col><Title level={4}>(Add link to Formstack or inquiry page)</Title></Col>
        </Row>
        <Row >
          <Input
            onChange={setBuyGroupTicketUrl}
            style={{ width: '100%' }}
            type="url"
            value= { buyGroupTicketUrl }
          />
        </Row>
      </Card>
    </div>
  )
}

BuyBox.propTypes = {
  buyGroupTicketUrl: PropTypes.string,
  buyTicketErr: PropTypes.string,
  buyTicketUrl: PropTypes.string,
  multiTicketUrl: PropTypes.string.isRequired,
  setBuyGroupTicketUrl: PropTypes.func.isRequired,
  setBuyTicketErr: PropTypes.func.isRequired,
  setBuyTicketUrl: PropTypes.func.isRequired,
  setMultiTicketUrl: PropTypes.func.isRequired
}

export default BuyBox
