/* eslint-disable camelcase */
import React from 'react'
import { Card, Col, Row, Input, Typography } from 'antd'
import PropTypes from 'prop-types'

import { ImageUploaderToS3 } from '~components'
import { Checkbox } from '~stories'
import styles from './HeroBasicInfo.module.css'

const { Title, Paragraph } = Typography

const EDPHeroInfo = ({
  handleCTALabelChange,
  handleCTAUrlChange,
  handleImageUrlUpdate,
  handleShowExternalCTAChange,
  hero
}) => {
  const {
    external_cta_url: ctaUrl,
    should_show_external_cta: showExternalCTA,
    external_cta_label: ctaLabel,
    url
  } = hero
  return (
    <div className={styles['hero-container']}>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col span={5}><Title level={3}>External CTA Url</Title></Col>
        </Row>
        <Row>
          <Input onChange={handleCTAUrlChange} style={{ width: '1200px' }} value={ctaUrl} />
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col span={5}><Title level={3}>CTA Label</Title></Col>
        </Row>
        <Row>
          <Input onChange={handleCTALabelChange} style={{ width: '1200px' }} value={ctaLabel} />
        </Row>
        <Paragraph />
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'bottom'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Show CTA</Title></Col>
          <Col span={6}>
            <Checkbox
              checked={showExternalCTA}
              onChange={handleShowExternalCTAChange}
            /></Col>
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row gutter={16}>
          <Col span={18}>
            <Title level={3}>Background Image</Title>
            <div>
              <ImageUploaderToS3 handleUpload={(thumbnailImageUrl, secureImageURL) => {
                const imageUrl = secureImageURL || thumbnailImageUrl
                handleImageUrlUpdate(imageUrl)
              }} logoUrl={url} />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

EDPHeroInfo.propTypes = {
  handleCTALabelChange: PropTypes.func,
  handleCTAUrlChange: PropTypes.func,
  handleImageUrlUpdate: PropTypes.func,
  handleShowExternalCTAChange: PropTypes.func,
  hero: PropTypes.object.isRequired
}

EDPHeroInfo.defaultProps = {
  hero: {}
}

export default EDPHeroInfo
