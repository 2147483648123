import React, { useEffect, useState } from 'react'
import { DeleteFilled } from '@ant-design/icons'
import { Button, Card, Collapse, Modal, Typography } from 'antd'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'

import { DragDropList } from '~stories'
import DataField from './DataField'

import './AntdPanel.css'
import styles from './EventDataFields.module.css'

const { Panel } = Collapse
const { Text } = Typography
const SortableItem = SortableElement(Panel)
const FIELDS_LIMIT = 9
const DEFAULT_DATA_FIELDS_OBJ = {
  cta_link_text: '',
  cta_link_url: '',
  description: '',
  image_url: '',
  selected_channels: [],
  title: '',
  video_platform: '',
  video_url: ''
}

const EventDataFields = (props) => {
  const [dataFields, setDataFields] = useState([])
  const [showDataFieldDeleteModal, setShowDataFieldDeleteModal] = useState(false)
  const [currentDataFieldIndex, setCurrentDataFieldIndex] = useState(-1)
  const [panelActiveKey, setPanelActiveKey] = useState(-1)

  useEffect(() => {
    if (props.dataFields) {
      setDataFields(props.dataFields)
    }
  }, [props.dataFields])

  const genExtra = (showErrorText, index) => (
    <>
      {
        !showErrorText && <Text className={styles.errorText}>Please update required fields.</Text>
      }
      <DeleteFilled
        className={styles.deleteIcon}
        onClick={event => {
          setCurrentDataFieldIndex(index)
          setShowDataFieldDeleteModal(true)
          event.stopPropagation()
        }}
      />
    </>
  )

  const addDataField = event => {
    event.preventDefault()
    const values = [...dataFields]
    const clonedDataFieldObj = JSON.parse(JSON.stringify(DEFAULT_DATA_FIELDS_OBJ))
    values.push(clonedDataFieldObj)
    setDataFields(values)
    props.handleDataFieldsUpdate(values)
  }

  const handleChannelUpdate = (selectedChannels, index) => {
    const clonedDataFields = [...dataFields]
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        selected_channels: selectedChannels
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleCtaLinkUpdate = (event, index) => {
    const clonedDataFields = [...dataFields]
    const ctaLink = event.target.value
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        cta_link_url: ctaLink
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleDescriptionUpdate = (event, index) => {
    const clonedDataFields = [...dataFields]
    const description = event.target.value
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        description
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleCtaTextUpdate = (event, index) => {
    const clonedDataFields = [...dataFields]
    const ctaText = event.target.value
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        cta_link_text: ctaText
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleTitleUpdate = (event, index) => {
    const clonedDataFields = [...dataFields]
    const title = event.target.value
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        title: title
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleImageUpload = (thumbnailImageUrl, secureImageURL, index) => {
    const clonedDataFields = [...dataFields]
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        image_url: secureImageURL || thumbnailImageUrl
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleVideoPlatformUpdate = (selectedItem, index) => {
    const clonedDataFields = [...dataFields]
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        video_platform: selectedItem || ''
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleVideoUrlUpdate = (event, index) => {
    const videoUrl = event.target.value
    const clonedDataFields = [...dataFields]
    const updatedDataFields = [
      ...clonedDataFields.slice(0, index),
      {
        ...clonedDataFields[index],
        video_url: videoUrl
      },
      ...clonedDataFields.slice(index + 1)
    ]
    setDataFields(updatedDataFields)
    props.handleDataFieldsUpdate(updatedDataFields)
  }

  const handleDeleteDataFieldModalCancel = () => {
    setCurrentDataFieldIndex(-1)
    setShowDataFieldDeleteModal(false)
  }

  const handleDeleteDataFieldAction = () => {
    const clonedDataFields = [...dataFields]
    if (currentDataFieldIndex !== -1) {
      clonedDataFields.splice(currentDataFieldIndex, 1)
    }
    setCurrentDataFieldIndex(-1)
    setShowDataFieldDeleteModal(false)
    setDataFields(clonedDataFields)
    props.handleDataFieldsUpdate(clonedDataFields)
  }

  const handleDataFieldUpdate = sortedIndexList => {
    const clonedDataFields = [...dataFields]
    const sortedList = sortedIndexList.map((index) => clonedDataFields[index])
    setDataFields(sortedList)
    props.handleDataFieldsUpdate(sortedList)
  }

  const handlePanelChange = activeKey => {
    setPanelActiveKey(activeKey)
  }

  const addMoreButton = <Button disabled={dataFields.length > FIELDS_LIMIT} onClick={addDataField} >Add More</Button>

  const allDataFields = dataFields?.map((dataField, index) => {
    const { isValid: showErrorText } = props.dataFieldErrors[index] || { isValid: true }

    return <SortableItem
      key={index}
      className={styles.draggableCard}
      extra={genExtra(showErrorText, index)}
      header={dataField.title}
      index={index}>
      <DataField
        dataField={dataField}
        handleChannelUpdate={handleChannelUpdate}
        handleCtaLinkUpdate={handleCtaLinkUpdate}
        handleCtaTextUpdate={handleCtaTextUpdate}
        handleDescriptionUpdate={handleDescriptionUpdate}
        handleImageUpload={handleImageUpload}
        handleTitleUpdate={handleTitleUpdate}
        handleVideoPlatformUpdate={handleVideoPlatformUpdate}
        handleVideoUrlUpdate={handleVideoUrlUpdate}
        index={index}
      />
    </SortableItem>
  })

  const hasDatafields = dataFields && dataFields.length > 0

  return (
    <Card extra={addMoreButton} title="Data Fields">
      {
        hasDatafields &&
          <DragDropList
            activeKey={panelActiveKey}
            dataSource={allDataFields}
            handlePanelChange={handlePanelChange}
            handleUpdate={handleDataFieldUpdate}
          />
      }
      {
        showDataFieldDeleteModal &&
          <Modal
            onCancel={handleDeleteDataFieldModalCancel}
            onOk={handleDeleteDataFieldAction}
            title="Delete confirmation"
            visible={showDataFieldDeleteModal}
          >
            <Text>
              This data field will be removed. Are you sure you want to delete this?
            </Text>
          </Modal>
      }
    </Card>
  )
}

EventDataFields.propTypes = {
  dataFieldErrors: PropTypes.object,
  dataFields: PropTypes.array,
  handleDataFieldsUpdate: PropTypes.func
}

EventDataFields.defaultProps = {
  dataFields: []
}

export default EventDataFields
