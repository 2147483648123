import React from 'react'
import { Card, Typography, Row, Col, Input } from 'antd'
import PropTypes from 'prop-types'
import { ImageUploaderToS3 } from '~components'
import { MultiSelectDropdown } from '~stories'

import styles from './EventInfo.module.css'

const { Title } = Typography

const EventInfo = ({ event, promotedArtists, allSubCategories, selectedSubCategories, handleEventDetailIconUpdate, selectedFlags, handleFlagsUpdate, handleSubCategoriesUpdate, handleCategoriesUpdate, selectedCategories, handleNameUpdate, handleSlug, handleSubtitleUpdate, handleEventArtist, handleEventStatus }) => {
  const isRoxEvent = event?.artists.map(artist => artist?.id).includes('K8vZ9171K10')
  const [series] = event?.series || []
  const { id: seriesId } = series || {}
  return (
    <div className={styles['event-information-event']}>
      <Title className={styles['title-text']} level={2}>Event Information</Title>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Name* </Title></Col>
          <Col><Title level={4}> (70 characters max; name of event)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              count={{ show: true, max: 70 }}
              onChange={handleNameUpdate}
              required
              style={{ width: '100%' }}
              value={event?.name || ''}
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Subtitle </Title></Col>
          <Col><Title level={4}>(128 characters max; input tour name, etc.)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              maxLength={128}
              onChange={handleSubtitleUpdate}
              style={{ width: '100%' }}
              value={event?.subTitle || ''}
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Image* </Title></Col>
          <Col><Title level={4}>(Upload PNG or JPG; preferred dimensions 0px x 0px; max file size 1MB)</Title></Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col align='end' justifyContent='left' span={18} style={{ display: 'flex' }}>
            <ImageUploaderToS3 handleUpload={handleEventDetailIconUpdate} logoUrl={event?.defaultImage?.url} />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Artist/Team/Performer* </Title></Col>
          <Col><Title level={4}>(Select one item or more from dropdown)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            {event?.allArtists && <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: '100%' }}
              displayField="title"
              dropdownOptions={event?.allArtists || []}
              handleChange={handleEventArtist}
              mode='multiple'
              selectedItems={promotedArtists}
              valueField="id"
            />}
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Status*</Title></Col>
          <Col><Title level={4}>(Select one item from dropdown)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <MultiSelectDropdown
              customStyle={{ width: '100%' }}
              displayField="label"
              dropdownOptions={event?.eventStatusList || []}
              handleChange={handleEventStatus}
              selectedItems={[event?.status]}
              valueField="value"
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Series*</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <a href={`/series/${seriesId}/edit`} rel='noreferrer' target='_blank'>{seriesId}</a>
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Slug*</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              onChange={handleSlug}
              style={{ width: '100%' }}
              value={event?.slug || ''}
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Filter Categories*</Title></Col>
          <Col><Title level={4}>(Select one or more items from dropdown)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 450 }}
              displayField="name"
              dropdownOptions={event?.allCategories || []}
              handleChange={handleCategoriesUpdate}
              mode='multiple'
              selectedItems={selectedCategories}
              valueField="name"
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Filter Subcategories*</Title></Col>
          <Col><Title level={4}>(Select one or more items from dropdown)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 450 }}
              displayField="name"
              dropdownOptions={allSubCategories || []}
              handleChange={handleSubCategoriesUpdate}
              mode='multiple'
              selectedItems={selectedSubCategories}
              valueField="name"
            />
          </Col>
        </Row>
      </Card>
      <Card className={styles['card-container']}>
        <Row align={'middle'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Flags*</Title></Col>
          <Col><Title level={4}>(Select one or more items from dropdown)</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 450 }}
              displayField="title"
              dropdownOptions={event?.msgFlagsList || []}
              handleChange={handleFlagsUpdate}
              selectedItems={selectedFlags}
              valueField="title"
            />
          </Col>
        </Row>
      </Card>
      {
        isRoxEvent && <>
          <Card className={styles['card-container']}>
            <Row align={'middle'} className={styles['title-wrapper']}>
              <Col><Title level={3}>Event Flags*</Title></Col>
              <Col><Title level={4}>(Select one or more items from dropdown)</Title></Col>
            </Row>
            <Row>
              <Col span={24}>
                <MultiSelectDropdown
                  allowClear={true}
                  customStyle={{ width: 450 }}
                  displayField="title"
                  dropdownOptions={event?.roxFlagsList || []}
                  handleChange={handleFlagsUpdate}
                  selectedItems={selectedFlags}
                  valueField="title"
                />
              </Col>
            </Row>
          </Card>
          <Card className={styles['card-container']}>
            <Row align={'middle'} className={styles['title-wrapper']}>
              <Col><Title level={3}>Groups Flags*</Title></Col>
              <Col><Title level={4}>(Select one or more items from dropdown)</Title></Col>
            </Row>
            <Row>
              <Col span={24}>
                <MultiSelectDropdown
                  allowClear={true}
                  customStyle={{ width: 450 }}
                  displayField="title"
                  dropdownOptions={event?.roxGroupsFlagsList || []}
                  handleChange={handleFlagsUpdate}
                  selectedItems={selectedFlags}
                  valueField="title"
                />
              </Col>
            </Row>
          </Card>
        </>
      }
    </div>
  )
}

EventInfo.propTypes = {
  allSubCategories: PropTypes.array,
  event: PropTypes.object,
  handleCategoriesUpdate: PropTypes.func.isRequired,
  handleEventArtist: PropTypes.func.isRequired,
  handleEventDetailIconUpdate: PropTypes.func.isRequired,
  handleEventStatus: PropTypes.func.isRequired,
  handleFlagsUpdate: PropTypes.func.isRequired,
  handleNameUpdate: PropTypes.func.isRequired,
  handleSlug: PropTypes.func.isRequired,
  handleSubCategoriesUpdate: PropTypes.func.isRequired,
  handleSubtitleUpdate: PropTypes.func.isRequired,
  promotedArtists: PropTypes.array,
  selectedCategories: PropTypes.array,
  selectedFlags: PropTypes.array,
  selectedSubCategories: PropTypes.array
}

export default EventInfo
