import React from 'react'

const UpArrow = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 25 24" width="18" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#0078D4" height="24" rx="3" transform="rotate(180 24.0745 24)" width="24.0744" x="24.0745" y="24" />
      <path d="M4.82667 13.8653L13.8653 4.82667L12.0386 3L3 12.0386L4.82667 13.8653Z" fill="white"/>
      <path d="M10.2119 4.82666L19.2477 13.8624L21.0744 12.0358L12.0386 3L10.2119 4.82666Z" fill="white"/>
      <path d="M10.6104 21L10.6104 4.82792L13.4643 4.82792L13.4643 21L10.6104 21Z" fill="white"/>
    </svg>

  )
}

export default UpArrow
