import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Layout, notification, PageHeader, Row, Space, Typography } from 'antd'
import idx from 'idx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchContentOpsById, resetEventActionStatus, updateContentOpsById } from '~actions'
import { BuyBox, EventInfo, EDPHeroInfo, EventDetail, EventRecommendations, InternalSearchOptimization, PageMetaInfoWithEditor } from '~components'
import { getCurrentUser } from '~context'
import { MultiSelectDropdown, Spinner, CustomSwitch } from '~stories'
import { getEventPulldownValue, getFileName } from '~utils'

import styles from './EditContentOps.module.css'

const PAGE_TITLE = 'Manage Event'
const { Content } = Layout
const { Paragraph, Title } = Typography

const FORMATTER = {
  DATE: 'MM/DD/YYYY',
  TIME: 'HH:mm:ss'
}
const TBA = 'TBA'

const MAX_PULL_DOWN_DAYS_FOR_POSTPONED_EVENT = 180
const ERROR_MESSAGES = {
  DATA_FIELD_ERR: 'Missing required attributes in data fields',
  PUBLISH_WARNING: 'Changes to publish status will not be saved until you click update.',
  SPOTIFY_URL_ERR: 'Please provide valid URL for Spotify playlist.'
}

const EditContentOps = ({ event, fetchContentOps, route, updateContentOpsById }) => {
  const eventInfoSectionRef = useRef(null)
  const callToActionSectionRef = useRef(null)
  const assetsSectionRef = useRef(null)
  const eventRecommendationSectionRef = useRef(null)
  const searchSectionRef = useRef(null)
  const menuRef = useRef(null)
  const [existingEvent, setExistingEvent] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(true)
  const [isDataUpdated, setIsDataUpdated] = useState(true)
  const [buyTicketErr, setBuyTicketErr] = useState(false)
  const [allKnowBeforeYouGo, setAllKnowBeforeYouGo] = useState([])
  const [allVenueFAQs, setAllVenueFAQs] = useState([])
  const [allGenres, setAllGenres] = useState([])
  const [allVenueBenefits, setAllVenueBenefits] = useState([])
  const [allSubscribe, setAllSubscribe] = useState([])
  const [allShopMerchandise, setAllShopMerchandise] = useState([])
  const [allViewSeatingChart, setAllViewSeatingChart] = useState([])
  // const [allEventDetails, setAllEventDetails] = useState([])
  // const [allGalleryDFields, setAllGalleryDFields] = useState([])
  const { access_token: accessToken } = getCurrentUser()
  const [promotedEvents, setPromotedEvents] = useState([])
  const [publish, setPublish] = useState()
  const [selectedGenres, setSelectedGenres] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [selectedEvents, setSelectedEvents] = useState([])
  const [curAvailableEvent, setCurAvailableEvent] = useState([])
  const [curSelectedEvent, setCurSelectedEvent] = useState([])
  const [eventDetails, setEventDetails] = useState(null)
  const [promotedArtists, setPromotedArtists] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedFlags, setSelectedFlags] = useState([])
  const [selectedRoxFlags, setSelectedRoxFlags] = useState([])
  const [selectedRoxGroupFlags, setSelectedRoxGroupFlags] = useState([])
  const [allSubCategories, setAllSubCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])

  useEffect(() => {
    const tmEventId = idx(route, _ => _.match.params.id)
    if (tmEventId) {
      fetchContentOps(tmEventId)
    }
  }, [route, fetchContentOps])

  useEffect(() => {
    if (event && event.id) {
      setAllKnowBeforeYouGo(event.allKnowBeforeYouGoDFields)
      setAllVenueFAQs(event.allVenueFAQsDFields)
      // setAllEventDetails(event.allEventDetailsDFields)
      setAllVenueBenefits(event.allVenueBenefitsDFields)
      setAllVenueBenefits(event.allVenueBenefitsDFields)
      setAllSubscribe(event.allSubscribeDFields)
      setPromotedEvents(event.relatedEvents)
      setAllShopMerchandise(event.allShopMerchandiseDFields)
      setAllViewSeatingChart(event.allViewSeatingChartDFields)
      // setAllGalleryDFields(event.allGalleryDFields)
      setPublish(event.publish)
      const selectedGenres = event.genres.map(({ name }) => name) || []
      setSelectedGenres(selectedGenres)
      setAllGenres(event.allGenres || [])
      setSelectedEvents(event?.relatedEvents)
      setCurAvailableEvent(event?.allUpsellEvents)
      setCurSelectedEvent(event?.relatedEvents)
      const selectedCategories = event?.category.map(({ name }) => name) || []
      const selectedFlags = event?.flags ? event?.flags.map(({ title }) => title) : []
      const selectedRoxFlags = event?.roxFlags ? event?.roxFlags.map(({ title }) => title) : []
      const selectedRoxGroupFlags = event?.roxGroupFlags ? event?.roxGroupFlags.map(({ title }) => title) : []
      const allSubCategories = event?.allCategories.map(eventCategory => eventCategory.sub_category).flat()
      const selectedSubCategories = event?.subCategory.map(({ name }) => name) || []

      const eventDetail = event?.event_details || {
        header: '',
        details: '',
        icon: {
          alt: '',
          url: ''
        }
      }
      setEventDetails(eventDetail)
      setAllEvents(event.allUpsellEvents)
      const selectedArtists = event?.artists.map(({ title }) => title) || []
      setPromotedArtists(selectedArtists)
      setExistingEvent({
        ...event,
        dbPublishStatus: event.publish,
        dbPostponedStatus: event.postponed,
        buyBoxCallToActions: {
          ...event.buyBoxCallToActions,
          buy_tickets_url: event?.hostUrl,
          buy_group_tickets_url: event?.buyBoxCallToActions?.buy_group_tickets_url || '',
          buy_multi_event_tickets_url: event?.buyBoxCallToActions?.buy_multi_event_tickets_url || ''
        }
      })
      setSelectedCategories(selectedCategories)
      setSelectedFlags(selectedFlags)
      setSelectedRoxFlags(selectedRoxFlags)
      setSelectedRoxGroupFlags(selectedRoxGroupFlags)
      setSelectedSubCategories(selectedSubCategories)
      setAllSubCategories(allSubCategories)

      setIsDataLoaded(true)
    }
  }, [event])

  const handleMenuClick = (key) => {
    if (key === 'eventInfoSectionRef') {
      eventInfoSectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (key === 'callToActionSectionRef') {
      callToActionSectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (key === 'assetsSectionRef') {
      assetsSectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (key === 'eventRecommendationSectionRef') {
      eventRecommendationSectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (key === 'searchSectionRef') {
      searchSectionRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleInternalSearch = (tags = []) => {
    const updatedEvent = {
      ...existingEvent,
      internalSearch: [
        ...tags
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleBuyGroupTickets = (event) => {
    const { buyBoxCallToActions } = existingEvent
    const updatedEvent = {
      ...existingEvent,
      buyBoxCallToActions: {
        ...buyBoxCallToActions,
        buy_group_tickets_url: event.target.value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const handleEventDetailDescriptionChange = (htmlDescription) => {
    const updatedEvent = {
      ...existingEvent,
      htmlDescription
    }
    setExistingEvent(updatedEvent)
  }

  const handleEventDetailHeaderChange = () => {

  }

  const handleEventDetailIconUpdate = (url) => {
    const {
      eventDetail = {
        header: '',
        details: '',
        icon: {
          alt: '',
          url: ''
        }
      }
    } = existingEvent
    const { icon = {} } = eventDetail
    const updatedEvent = {
      ...existingEvent,
      eventDetail: {
        ...eventDetail,
        icon: {
          ...icon,
          url
        }
      }
    }
    setEventDetails(updatedEvent.eventDetail)
    setExistingEvent(updatedEvent)
  }

  const handleDefaultImageUpdate = (url) => {
    const {
      defaultImage = {
        url: '',
        height: null,
        weight: null
      }
    } = existingEvent
    const updatedEvent = {
      ...existingEvent,
      defaultImage: {
        ...defaultImage,
        url
      }
    }
    setEventDetails(updatedEvent.eventDetail)
    setExistingEvent(updatedEvent)
  }

  const handleEventDetailNotificationChange = () => {

  }

  const handleBuyMultiDayTickets = (event) => {
    const { buyBoxCallToActions } = existingEvent
    const updatedEvent = {
      ...existingEvent,
      buyBoxCallToActions: {
        ...buyBoxCallToActions,
        buy_multi_event_tickets_url: event.target.value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const setPublishStatus = publish => {
    setPublish(publish)
    notification.warning({ message: ERROR_MESSAGES.PUBLISH_WARNING })
    const updatedEvent = { ...existingEvent, publish }
    setExistingEvent(updatedEvent)
  }

  const handleBuyTickets = (event) => {
    const { buyBoxCallToActions } = existingEvent
    setBuyTicketErr(false)
    if (!event.target.value) {
      setBuyTicketErr(true)
    }
    const updatedEvent = {
      ...existingEvent,
      buyBoxCallToActions: {
        ...buyBoxCallToActions,
        buy_tickets_url: event.target.value
      }
    }
    setExistingEvent(updatedEvent)
  }

  const handleKnowBeforeYouGoChange = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      knowBeforeYouGo: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handleVenueFAQsChange = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      venue_faqs: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handleVenueBenefits = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      venue_benefits: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handleSubscribeChange = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      subscribe: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handleShopMerchandiseChange = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      shop_merchandise: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handleViewSeatingChartChange = (selectedItem) => {
    const updatedEvent = {
      ...existingEvent,
      view_seating_chart: selectedItem
    }
    setExistingEvent(updatedEvent)
  }

  const handlePageMetaDescriptionChange = (description) => {
    const { page_meta: pageMeta = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        description
      }
    })
  }

  const handlePageMetaHeadingChange = (event) => {
    const { page_meta: pageMeta = {} } = existingEvent
    const heading = event.target.value

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        heading
      }
    })
  }

  const handlePageMetaKeywordsChange = (event) => {
    const keywords = event.target.value
    const { page_meta: pageMeta = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        keywords
      }
    })
  }

  const handlePageMetaShowSEOChange = (event) => {
    const showSEO = event.target.checked
    const { page_meta: pageMeta = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        should_show_seo: showSEO
      }
    })
  }

  const handlePageMetaTextChange = (text) => {
    const { page_meta: pageMeta = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        text
      }
    })
  }

  const handlePageMetaTitleChange = (event) => {
    const title = event.target.value
    const { page_meta: pageMeta } = existingEvent

    setExistingEvent({
      ...existingEvent,
      page_meta: {
        ...pageMeta,
        title
      }
    })
  }

  const handleCTALabelChange = (event) => {
    const externalCTALabel = event.target.value
    const { external_hero_cta: externalCTA = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      external_hero_cta: {
        ...externalCTA,
        external_cta_label: externalCTALabel
      }
    })
  }

  const handleNameUpdate = event => {
    const name = event.target.value
    const updatedEvent = { ...existingEvent, name }
    setExistingEvent(updatedEvent)
  }

  const handleSlug = event => {
    const slug = event.target.value
    const updatedEvent = { ...existingEvent, slug }
    setExistingEvent(updatedEvent)
  }

  const handleSubtitleUpdate = event => {
    const subTitle = event.target.value
    const updatedEvent = { ...existingEvent, subTitle }
    setExistingEvent(updatedEvent)
  }

  const handleSubCategoriesUpdate = selectedItems => {
    const subCategory = selectedItems.map(name => ({ name }))
    setSelectedSubCategories(selectedItems)

    const subCategoryList = subCategory.map(item => item.name)
    const selectedSubCategories = allSubCategories.filter(eventCategory => subCategoryList.includes(eventCategory.name))
    const updatedEvent = {
      ...existingEvent,
      sub_category: [
        ...selectedSubCategories
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleEventStatus = status => {
    const { startDate, artists, startTime, timezone } = existingEvent
    const eventStartTime = (startTime === TBA) ? TBA : startTime
    const updatedStartDate = status === 'postponed' ? moment.tz(startDate, timezone).add(MAX_PULL_DOWN_DAYS_FOR_POSTPONED_EVENT, 'days').format(FORMATTER.DATE) : startDate
    const pullDownFromSystem = getEventPulldownValue(updatedStartDate, eventStartTime, artists, timezone)
    const statusText = status === 'postponed' ? 'Postponed' : ''
    const eventStatus = status === 'postponed' ? {
      postponed: true,
      pullDownFromSystem,
      statusText,
      status
    } : {
      status
    }
    const updatedEvent = {
      ...existingEvent,
      ...eventStatus
    }
    setExistingEvent(updatedEvent)
  }

  const handleFlagsUpdate = selectedItem => {
    setSelectedFlags(selectedItem)
    const selectedFlags = existingEvent.allFlags.filter(flag => [selectedItem].includes(flag.title))

    const updatedEvent = {
      ...existingEvent,
      flags: [
        ...selectedFlags
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleEventArtist = selectedItems => {
    setPromotedArtists(selectedItems)
    const selectedArtists = existingEvent.allArtists.filter(artist => [selectedItems].includes(artist.title))

    const updatedEvent = {
      ...existingEvent,
      artists: [
        ...selectedArtists
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleCategoriesUpdate = selectedItems => {
    const category = selectedItems.map(name => ({ name }))
    setSelectedCategories(selectedItems)
    const categoryList = category.map(item => item.name)
    const selectedCategories = existingEvent.allCategories.filter(eventCategory => categoryList.includes(eventCategory.name))

    const updatedEvent = {
      ...existingEvent,
      category: [
        ...selectedCategories
      ]
    }
    setExistingEvent(updatedEvent)
  }

  const handleCTAUrlChange = (event) => {
    const externalCTAUrl = event.target.value
    const { external_hero_cta: externalCTA = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      external_hero_cta: {
        ...externalCTA,
        external_cta_url: externalCTAUrl
      }
    })
  }

  const handleShowExternalCTAChange = (event) => {
    const showCTA = event.target.checked
    const { external_hero_cta: externalCTA = {} } = existingEvent

    setExistingEvent({
      ...existingEvent,
      external_hero_cta: {
        ...externalCTA,
        should_show_external_cta: showCTA
      }
    })
  }

  const handleHeroImageUrlUpdate = (imageUrl) => {
    const { hero = {} } = existingEvent

    const alt = getFileName(imageUrl)
    setExistingEvent({
      ...existingEvent,
      hero: {
        ...hero,
        alt,
        url: imageUrl
      }
    })
  }

  const handleGenresUpdate = selectedItems => {
    const genres = selectedItems.map(name => ({ name }))
    const updatedEvent = { ...existingEvent, genres }
    setExistingEvent(updatedEvent)
    setSelectedGenres(selectedItems)
  }

  const handleSubmit = () => {
    setBuyTicketErr(false)
    if (!existingEvent?.buyBoxCallToActions?.buy_tickets_url) {
      setBuyTicketErr(true)
      return
    }
    const dataFields = {
      event_details: existingEvent.event_details,
      knowBeforeYouGo: existingEvent.knowBeforeYouGo,
      venue_faqs: existingEvent.venue_faqs,
      photos_videos: existingEvent.photos_videos,
      venue_benefits: existingEvent.venue_benefits,
      subscribe: existingEvent.subscribe,
      view_seating_chart: existingEvent.view_seating_chart,
      shop_merchandise: existingEvent.shop_merchandise
    }
    const relatedEvents = promotedEvents.map(({ id }) => id)

    setIsDataUpdated(false)
    const { id } = existingEvent
    const eventObj = {
      ...existingEvent,
      dataFields,
      relatedEvents
    }

    updateContentOpsById(id, eventObj, accessToken)
  }

  const publishSwitch = <CustomSwitch
    checked={publish}
    isInline={true}
    label="Publish"
    onChange={setPublishStatus}
  />

  const submitButton = <Button className={styles.updateActionButton} loading={!isDataUpdated} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  const deleteButton = <Button className={styles.deleteActionButton} type='danger'>
    Delete
  </Button>

  return (
    <Layout className={styles.eventLayout}>
      <ul ref={menuRef} className={styles.menu} onClick={() => {}}>
        <li key="eventInfoSectionRef" onClick={() => handleMenuClick('eventInfoSectionRef')}>Event Information</li>
        <li key="callToActionSectionRef" onClick={() => handleMenuClick('callToActionSectionRef')} >Buy Box & Call-to-Action</li>
        <li key="assetsSectionRef" onClick={() => handleMenuClick('assetsSectionRef')} >Photos & Videos</li>
        <li key="eventRecommendationSectionRef" onClick={() => handleMenuClick('eventRecommendationSectionRef')} >Recommended Events</li>
        <li key="searchSectionRef" onClick={() => handleMenuClick('searchSectionRef')} >Search Engine Optimization</li>
      </ul>
      <PageHeader className={styles.headline} extra={[publishSwitch, submitButton, deleteButton]} title={PAGE_TITLE} />
      <Content className={styles.contentContainer}>
        <div>
          <Title className={styles.title}>Hero</Title>
          <EDPHeroInfo
            handleCTALabelChange={handleCTALabelChange}
            handleCTAUrlChange={handleCTAUrlChange}
            handleImageUrlUpdate={handleHeroImageUrlUpdate}
            handleShowExternalCTAChange={handleShowExternalCTAChange}
            hero={{
              ...existingEvent?.external_hero_cta,
              ...existingEvent?.hero
            } || {}}
          />
        </div><br />
        <div>
          <Title className={styles.title}>Page Meta</Title>
          <PageMetaInfoWithEditor
            handleDescriptionChange={handlePageMetaDescriptionChange}
            handleHeadingChange={handlePageMetaHeadingChange}
            handleKeywordsChange={handlePageMetaKeywordsChange}
            handleShowSEOChange={handlePageMetaShowSEOChange}
            handleTextChange={handlePageMetaTextChange}
            handleTitleChange={handlePageMetaTitleChange}
            pageMeta={existingEvent?.page_meta || {}}
          />
        </div>
        <div ref={eventInfoSectionRef}>
          <EventInfo
            allSubCategories={allSubCategories}
            event={existingEvent}
            handleCategoriesUpdate={handleCategoriesUpdate}
            handleEventArtist={handleEventArtist}
            handleEventDetailIconUpdate={handleDefaultImageUpdate}
            handleEventStatus={handleEventStatus}
            handleFlagsUpdate={handleFlagsUpdate}
            handleNameUpdate={handleNameUpdate}
            handleSlug={handleSlug}
            handleSubCategoriesUpdate={handleSubCategoriesUpdate}
            handleSubtitleUpdate={handleSubtitleUpdate}
            promotedArtists={promotedArtists}
            selectedCategories={selectedCategories}
            selectedFlags={selectedFlags}
            selectedRoxFlags={selectedRoxFlags}
            selectedRoxGroupFlags={selectedRoxGroupFlags}
            selectedSubCategories={selectedSubCategories}
          />
        </div>
        <div ref={callToActionSectionRef}>
          {existingEvent?.buyBoxCallToActions && <BuyBox
            buyGroupTicketUrl={existingEvent?.buyBoxCallToActions?.buy_group_tickets_url || ''}
            buyTicketErr={buyTicketErr}
            buyTicketUrl={existingEvent?.buyBoxCallToActions?.buy_tickets_url}
            multiTicketUrl={existingEvent?.buyBoxCallToActions?.buy_multi_event_tickets_url || ''}
            setBuyGroupTicketUrl={handleBuyGroupTickets}
            setBuyTicketErr={setBuyTicketErr}
            setBuyTicketUrl={handleBuyTickets}
            setMultiTicketUrl={handleBuyMultiDayTickets}
          />}
        </div>
        <div>
          {eventDetails && existingEvent && <EventDetail
            eventDetail={eventDetails}
            handleEventDetailDescriptionChange={handleEventDetailDescriptionChange}
            handleEventDetailHeaderChange={handleEventDetailHeaderChange}
            handleEventDetailIconUpdate={handleEventDetailIconUpdate}
            handleEventDetailNotificationChange={handleEventDetailNotificationChange}
            tmDescription={existingEvent?.htmlDescription}
          />}
        </div>
        <div>
          <Title className={styles.title}>Videos & Photos</Title>
        </div>
        <div>
          <Title className={styles.title}>Know Before You Go</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allKnowBeforeYouGo}
            handleChange={handleKnowBeforeYouGoChange}
            selectedItems={existingEvent?.dataFields?.knowBeforeYouGo}
            valueField='_id'
          />
        </div>
        <div>
          <Title className={styles.title}>Venue FAQs</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allVenueFAQs}
            handleChange={handleVenueFAQsChange}
            selectedItems={existingEvent?.dataFields?.venue_faqs}
            valueField='_id'
          />
        </div>
        <div>
          <Title className={styles.title}>Venue Benefits</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allVenueBenefits}
            handleChange={handleVenueBenefits}
            selectedItems={existingEvent?.dataFields?.venue_benefits}
            valueField='_id'
          />
        </div>
        <div>
          <Title className={styles.title}>Subscribe</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allSubscribe}
            handleChange={handleSubscribeChange}
            selectedItems={existingEvent?.dataFields?.subscribe}
            valueField='_id'
          />
        </div>
        <div>
          <Title className={styles.title}>Shop Merchandise</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allShopMerchandise}
            handleChange={handleShopMerchandiseChange}
            selectedItems={existingEvent?.dataFields?.shop_merchandise}
            valueField='_id'
          />
        </div>
        <div>
          <Title className={styles.title}>View Seating Chart</Title>
          <MultiSelectDropdown
            allowClear={true}
            customStyle={{ width: 450 }}
            displayField='title'
            dropdownOptions={allViewSeatingChart}
            handleChange={handleViewSeatingChartChange}
            selectedItems={existingEvent?.dataFields?.view_seating_chart}
            valueField='_id'
          />
        </div>
        <div ref={eventRecommendationSectionRef} className={styles.recomondedEventsContainer}>
          <Title className={styles.title}>Recommended Events (e.g. People Also Viewed)</Title>
          <div className={styles['genres-wrapper']}>
            <Card className={styles['card-container']}>
              <Row align={'middle'} className={styles['title-wrapper']}>
                <Col><Title level={3}>Personalization Genres*</Title></Col>
                <Col><Title level={4}>(Select genre to populate events for people also viewed module)</Title></Col>
              </Row>
              <Row>
                <Space>
                  {existingEvent && <MultiSelectDropdown
                    allowClear={true}
                    customStyle={{ width: 450 }}
                    displayField="name"
                    dropdownOptions={allGenres}
                    handleChange={handleGenresUpdate}
                    mode='multiple'
                    placeholder='(Please select)'
                    selectedItems={selectedGenres}
                    valueField="name"
                  />}
                </Space>
              </Row>
              {selectedGenres?.length === 0 && <Paragraph className={styles['paragraph-text']}>Please select an option</Paragraph>}
            </Card>
          </div>
          <Card className={styles['card-container']}>
            <Row className={styles['title-wrapper']}>
              <Col><Title level={3}>{'Personalization Events* '} </Title></Col>
              <Col><Title level={4}>(X events max; originally auto-populates based on personalization genre selection, but user can add/remove events)</Title></Col>
            </Row>
            <EventRecommendations availableList={allEvents} curAvailableEvent={curAvailableEvent} curSelectedEvent={curSelectedEvent} selectedList={selectedEvents} setCurAvailableEvent={setCurAvailableEvent} setCurSelectedEvent={setCurSelectedEvent} />
            {curSelectedEvent.length < 1 && <Paragraph className={styles['paragraph-text']}>Please select an option</Paragraph>}
          </Card>
        </div>
        {/* <div ref={searchSectionRef}>
          <Title className={styles.title}>Search Engine Optimization</Title>
        </div> */}
        <div>
          <InternalSearchOptimization setTags={handleInternalSearch} tags={existingEvent?.internalSearch || []} />
        </div>
      </Content>
      <Spinner isLoading={!isDataLoaded} />
    </Layout>
  )
}

EditContentOps.propTypes = {
  deleteEvent: PropTypes.func.isRequired,
  deleteMessage: PropTypes.string,
  deleteStatus: PropTypes.bool,
  event: PropTypes.object.isRequired,
  fetchContentOps: PropTypes.func.isRequired,
  resetEventActionStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateContentOpsById: PropTypes.func.isRequired,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

const mapStateToProps = ({ contentOps }) => ({
  event: contentOps
})

const mapDispatchToProps = {
  fetchContentOps: fetchContentOpsById,
  resetEventActionStatus,
  updateContentOpsById
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditContentOps)
)
