import React from 'react'

const DownArrow = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 25 24" width="18" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#0078D4" height="24" rx="2" width="24.0744" x="0.0744629" />
      <path d="M19.3223 10.1347L10.2836 19.1733L12.1103 21L21.1489 11.9614L19.3223 10.1347Z" fill="white"/>
      <path d="M13.937 19.1733L4.90118 10.1376L3.07451 11.9642L12.1103 21L13.937 19.1733Z" fill="white"/>
      <path d="M13.5385 3L13.5385 19.1721L10.6846 19.1721L10.6846 3L13.5385 3Z" fill="white"/>
    </svg>

  )
}

export default DownArrow
