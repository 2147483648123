export const serializerContentOps = (eventObj) => {
  const {
    artists,
    category,
    dataFields,
    dateTime,
    dbPostponedStatus,
    dbPublishStatus,
    doNotOverrideRecommendations,
    doorOpenTime,
    endDate,
    entranceInfo,
    eventLogo,
    eventObjectId,
    internalSearch,
    generalInfo,
    genres,
    flags,
    hero,
    external_hero_cta: externalHeroCTA,
    roxFlags,
    roxGroupFlags,
    name,
    postponed,
    postponedText,
    premiumPartners,
    publish,
    pullDownFromSystem,
    relatedEvents,
    seriesId,
    socialTags,
    spanMultipleDays,
    sponsors,
    spotifyPlaylistUrl,
    status,
    buyBoxCallToActions,
    startTime,
    statusText,
    subTitle,
    subCategory,
    tags,
    useSponsorLogoForVenue
  } = eventObj

  return {
    ...(spanMultipleDays && { date_time: dateTime }),
    artists,
    category,
    buy_box_call_to_actions: buyBoxCallToActions,
    sub_category: subCategory,
    data_fields: dataFields,
    dbPublishStatus,
    dbPostponedStatus,
    do_not_override_recommendations: doNotOverrideRecommendations,
    door_open_time: doorOpenTime,
    end_date: endDate,
    entrance_info: entranceInfo,
    event_logo: eventLogo,
    eventObjectId,
    general_info: generalInfo,
    genres,
    hero,
    external_hero_cta: externalHeroCTA,
    flags,
    internal_search: internalSearch,
    event_type: eventObj.event_type || eventObj.eventType || [],
    rox_flags: roxFlags,
    rox_group_flags: roxGroupFlags,
    name,
    postponed,
    page_meta: eventObj.page_meta,
    postponed_text: postponedText,
    premium_partners: premiumPartners,
    publish,
    pull_down_from_system: pullDownFromSystem,
    related_events: relatedEvents,
    seriesId,
    social_tags: socialTags,
    spanMultipleDays: spanMultipleDays,
    sponsors,
    spotify_playlist_url: spotifyPlaylistUrl,
    start_time: startTime,
    status,
    status_text: statusText,
    sub_title: subTitle,
    tags,
    use_sponsor_logo_for_venue: useSponsorLogoForVenue
  }
}
