import React from 'react'
import { Button, Card, Col, Collapse, Row, Space, Typography } from 'antd'
import PropTypes from 'prop-types'
import { LogoUploader } from '~components'
import { Input, Label, RichTextInput, MultiSelectDropdown } from '~stories'
import { LINK_TARGETS } from '../../constants'
import GalleryFlexModule from './GalleryType'

const { Title, Text } = Typography
const { Panel } = Collapse

const DefaultFlexModule = ({
  addNewGalleryInFlexModule,
  dataField,
  deleteFlexModuleCTA,
  deleteAllGalleryFlexModule,
  handleMapEmbedChange,
  deleteFlexModuleMapEmbedUrl,
  deleteGalleryFlexModule,
  fieldIndex,
  handleCTALabelChange,
  handleCTATargetChange,
  handleCTAUrlChange,
  handleDescriptionChange,
  handleFlexModuleGalleryMediaChange,
  handleGalleryImageUrlUpdate,
  handleGallerySubTitleChange,
  handleGalleryTitleChange,
  handleGalleryImageAltChange,
  handleImageUrlUpdate,
  handleTitleChange
}) => {
  return (
    <Card
      style={{ width: '100%', marginTop: 8 }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>Title*</Title>&nbsp;<Text>(70 characters max)</Text></Row>
          <Input maxLength={70} onChange={(event) => handleTitleChange(event, fieldIndex)} value={dataField?.title} />
        </Col>
      </Row><br/>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>Description</Title>&nbsp;<Text /></Row>
          <RichTextInput onChange={(description) => handleDescriptionChange(description, fieldIndex)} value={dataField?.description} />
        </Col>
      </Row>
      {dataField.cta && <Row gutter={16}>
        <Col span={24}>
          <Card
            extra={
              <Button danger onClick={(event) => deleteFlexModuleCTA(event, fieldIndex)} style={{ backgroundColor: '#DA0D50' }} type="primary">Delete</Button>
            }
            style={{ width: '100%', marginTop: 8 }}
            title='CTA'
          >
            <Input label='Label' maxLength={70} onChange={(event) => handleCTALabelChange(event, fieldIndex)} value={dataField?.cta?.label}/>
            <Input label='Url' onChange={(event) => handleCTAUrlChange(event, fieldIndex)} value={dataField?.cta?.url} />
            <MultiSelectDropdown
              allowClear={true}
              customStyle={{ width: 150 }}
              displayField='label'
              dropdownOptions={LINK_TARGETS}
              handleChange={(event) => handleCTATargetChange(event, fieldIndex)}
              label='Target'
              selectedItems={dataField?.cta?.target}
              valueField='value'
            />
          </Card>
        </Col>
      </Row>}
      <Row gutter={16}>
        <Col span={18}>
          <Label value='Image' />
          <div>
            <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
              const imageUrl = secureImageURL || thumbnailImageUrl
              handleImageUrlUpdate(imageUrl, fieldIndex)
            }} logoUrl={dataField?.image?.url} />
          </div>
        </Col>
      </Row>
      {dataField?.map_src_url !== null && <Row gutter={16}>
        <Col span={24}>
          <Card
            extra={
              <Button danger onClick={(event) => deleteFlexModuleMapEmbedUrl(event, fieldIndex)} style={{ backgroundColor: '#DA0D50' }} type="primary">Delete</Button>
            }
            style={{ width: '100%', marginTop: 8 }}
            title='Map Embed'
          >
            <Row gutter={16} style={{ marginTop: 18 }}>
              <Col span={24}>
                <Row gutter={16}>
                  <Title level={5}>Map Url*</Title>
                </Row>
                <Input onChange={(event) => handleMapEmbedChange(event, fieldIndex)} value={dataField?.map_src_url} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>}
      {dataField?.gallery !== null && <Row gutter={16}>
        <Col span={24}>
          <Card
            extra={<Space>
              <Button onClick={(event) => addNewGalleryInFlexModule(event, fieldIndex)} style={{ backgroundColor: '#0078D4' }} type="primary">Add Gallery</Button>
              <Button danger onClick={(event) => deleteAllGalleryFlexModule(event, fieldIndex)} style={{ backgroundColor: '#DA0D50', color: '#fff' }}>Delete All</Button>
            </Space>
            }
            style={{ width: '100%', marginTop: 8 }}
            title='Gallery'
          >
            <Collapse accordion defaultActiveKey={['0']} expandIconPosition='right'>
              {dataField?.gallery.map((gallery, dataFieldGalleryIndex) => (<Panel key={dataFieldGalleryIndex}
                extra={<Row gutter={2}><Space>
                  <Button danger disabled={gallery.cta} onClick={(event) => deleteGalleryFlexModule(event, dataFieldGalleryIndex, fieldIndex)} style={{ backgroundColor: '#DA0D50', color: '#fff' }}>Delete</Button>
                </Space>
                </Row>
                }
                header={<span style={{
                  fontSize: 20
                }}>{gallery?.title}</span>}
              >
                <GalleryFlexModule
                  fieldIndex={fieldIndex}
                  gallery={gallery}
                  galleryIndex={dataFieldGalleryIndex}
                  handleFlexModuleGalleryMediaChange={handleFlexModuleGalleryMediaChange}
                  handleGalleryImageAltChange={handleGalleryImageAltChange}
                  handleGalleryImageUrlUpdate={handleGalleryImageUrlUpdate}
                  handleGallerySubTitleChange={handleGallerySubTitleChange}
                  handleGalleryTitleChange={handleGalleryTitleChange}
                />
              </Panel>)
              )}
            </Collapse>
          </Card>
        </Col>
      </Row>}
    </Card>
  )
}

DefaultFlexModule.propTypes = {
  addNewGalleryInFlexModule: PropTypes.func,
  dataField: PropTypes.object,
  deleteAllGalleryFlexModule: PropTypes.func,
  deleteFlexModuleCTA: PropTypes.func,
  deleteFlexModuleMapEmbedUrl: PropTypes.func,
  deleteGalleryFlexModule: PropTypes.func,
  fieldIndex: PropTypes.number,
  handleCTALabelChange: PropTypes.func,
  handleCTATargetChange: PropTypes.func,
  handleCTAUrlChange: PropTypes.func,
  handleDescriptionChange: PropTypes.func,
  handleFlexModuleGalleryMediaChange: PropTypes.func,
  handleGalleryImageAltChange: PropTypes.func,
  handleGalleryImageUrlUpdate: PropTypes.func,
  handleGallerySubTitleChange: PropTypes.func,
  handleGalleryTitleChange: PropTypes.func,
  handleImageUrlUpdate: PropTypes.func,
  handleMapEmbedChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  title: PropTypes.string
}

DefaultFlexModule.defaultProps = {
}

export default DefaultFlexModule
