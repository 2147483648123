import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'
import { LogoUploader } from '~components'
import { Input, Label, MultiSelectDropdown } from '~stories'

const SOURCE_TYPE = [{
  label: 'Image',
  value: 'image'
}, {
  label: 'Video',
  value: 'video'
}]

const { Title, Text } = Typography

const GalleryFlexModule = ({ fieldIndex, handleGalleryTitleChange, handleGallerySubTitleChange, handleFlexModuleGalleryMediaChange, handleGalleryImageUrlUpdate, handleGalleryImageAltChange, galleryIndex, gallery }) => {
  return (
    <Card
      style={{ width: '100%', marginTop: 8 }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>Title*</Title>&nbsp;<Text>(70 characters max)</Text></Row>
          <Input onChange={(event) => handleGalleryTitleChange(event, fieldIndex, galleryIndex)} value={gallery?.title} />
        </Col>
      </Row><br/>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}><Title level={5}>SubTitle</Title></Row>
          <Input onChange={(event) => handleGallerySubTitleChange(event, fieldIndex, galleryIndex)} value={gallery?.subtitle} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <Row gutter={16}>
            <Col span={8}>
              <MultiSelectDropdown
                allowClear={true}
                customStyle={{ width: 150 }}
                displayField='label'
                dropdownOptions={SOURCE_TYPE}
                handleChange={(selectedItems) => handleFlexModuleGalleryMediaChange(selectedItems, fieldIndex, galleryIndex)}
                label='Media Type'
                selectedItems={gallery?.mediaType}
                valueField='value'
              />
            </Col>
          </Row>
          {
            gallery?.mediaType === 'image' && <><Label value='Image' />
              <div>
                <Row gutter={16}>
                  <Col span={8}>
                    <LogoUploader handleUpload={(thumbnailImageUrl, secureImageURL) => {
                      const imageUrl = secureImageURL || thumbnailImageUrl
                      handleGalleryImageUrlUpdate(imageUrl, fieldIndex, galleryIndex)
                    }} logoUrl={gallery?.src} />
                  </Col>
                  <Col span={8}>
                    <Row gutter={16}>Alt</Row>
                    <Input onChange={(event) => handleGalleryImageAltChange(event, fieldIndex, galleryIndex)} value={gallery?.alt} />
                  </Col>
                </Row>
              </div></>
          }
          {
            gallery?.mediaType === 'video' && <><Label value='Video Url' />
              <div>
                <Row gutter={16}>
                  <Col span={24}>
                    <Input onChange={(event) => handleGalleryImageUrlUpdate(event.target.value, fieldIndex, galleryIndex)} value={gallery?.src} />
                  </Col>
                  <Col span={8}>
                    <Row gutter={16}>Alt</Row>
                    <Input onChange={(event) => handleGalleryImageAltChange(event, fieldIndex, galleryIndex)} value={gallery?.alt} />
                  </Col>
                </Row>
              </div></>
          }
        </Col>
      </Row>
    </Card>
  )
}

GalleryFlexModule.propTypes = {
  fieldIndex: PropTypes.number,
  gallery: PropTypes.object,
  galleryIndex: PropTypes.number,
  handleFlexModuleGalleryMediaChange: PropTypes.func,
  handleGalleryImageAltChange: PropTypes.func,
  handleGalleryImageUrlUpdate: PropTypes.func,
  handleGallerySubTitleChange: PropTypes.func,
  handleGalleryTitleChange: PropTypes.func,
  handleImageUrlUpdate: PropTypes.func
}

GalleryFlexModule.defaultProps = {
}

export default GalleryFlexModule
