import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styles from './RichTextContentEditor.module.css'

const RichTextContentEditor = ({ onChange, value }) => {
  const quillRef = useRef()
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }]
        ]
      },
      clipboard: {
        matchVisual: false
      }
    }),
    []
  )

  const formats = [
    'bold',
    'italic',
    'list',
    'link',
    'bullet',
    'indent',
    'color',
    'clean'
  ]

  return (
    <div className={styles.wrapper}>
      <ReactQuill
        ref={quillRef}
        className={styles.editor}
        formats={formats}
        modules={modules}
        onChange={onChange}
        theme='snow'
        value={value}
      />
    </div>
  )
}

RichTextContentEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default RichTextContentEditor
