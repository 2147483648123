export const ADD_NEW_CONTENT_MODULE = 'ADD_NEW_CONTENT_MODULE'
export const ADD_NEW_DATA_FIELD = 'ADD_NEW_DATA_FIELD'
export const ADD_NEW_FLAG = 'ADD_NEW_FLAG'
export const ADD_NEW_HERO = 'ADD_NEW_HERO'
export const ADD_NEW_OPT_IN = 'ADD_NEW_OPT_IN'
export const ADD_NEW_PAGE = 'ADD_NEW_PAGE'
export const ADD_NEW_SPONSOR = 'ADD_NEW_SPONSOR'
export const ADD_NEW_SUITE_CARD_MODULE = 'ADD_NEW_SUITE_CARD_MODULE'
export const ADD_ORGANISATION = 'ADD_ORGANISATION'
export const ADD_PRICE_TEMPLATE = 'ADD_PRICE_TEMPLATE'
export const ADD_PROMO_CODE = 'ADD_PROMO_CODE'
export const ADD_SERIES_STATUS = 'ADD_SERIES_STATUS'
export const ADD_VENUE = 'ADD_VENUE'
export const ARTIST_UPDATE_STATUS = 'ARTIST_UPDATE_STATUS'
export const CONTENT_MODULE_UPDATE_STATUS = 'CONTENT_MODULE_UPDATE_STATUS'
export const CONTENT_UPDATE_STATUS = 'CONTENT_UPDATE_STATUS'
export const DATA_FIELD_UPDATE_STATUS = 'DATA_FIELD_UPDATE_STATUS'
export const FETCH_ALL_PRICE_TEMPLATES = 'FETCH_ALL_PRICE_TEMPLATES'
export const FETCH_ALL_SERIES = 'FETCH_ALL_SERIES'
export const FETCH_ARTISTS = 'FETCH_ARTISTS'
export const FETCH_CONTENT_MODULES = 'FETCH_CONTENT_MODULES'
export const FETCH_CONTENT_OPS_BY_ID = 'FETCH_CONTENT_OPS_BY_ID'
export const FETCH_CONTENTS = 'FETCH_CONTENTS'
export const FETCH_DASHBOARD_STATS = 'FETCH_DASHBOARD_STATS'
export const FETCH_DATA_FIELDS = 'FETCH_DATA_FIELDS'
export const FETCH_EVENT_BY_ID = 'FETCH_EVENT_BY_ID'
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_FLAGS = 'FETCH_FLAGS'
export const FETCH_HERO_CONTENTS = 'FETCH_HERO_CONTENTS'
export const FETCH_NAVIGATIONS = 'FETCH_NAVIGATIONS'
export const FETCH_NEW_FLAG = 'FETCH_NEW_FLAG'
export const FETCH_NEW_OPT_IN = 'FETCH_NEW_OPT_IN'
export const FETCH_NEW_PRICE_TEMPLATE = 'FETCH_NEW_PRICE_TEMPLATE'
export const FETCH_NEW_SPONSOR = 'FETCH_NEW_SPONSOR'
export const FETCH_OPT_INS = 'FETCH_OPT_INS'
export const FETCH_ORGANISATION_TO_EDIT = 'FETCH_ORGANISATION_TO_EDIT'
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS'
export const FETCH_PAGE_CONTENTS = 'FETCH_PAGE_CONTENTS'
export const FETCH_PRESIGNED_URL = 'FETCH_PRESIGNED_URL'
export const FETCH_PRICE_TEMPLATE_TO_COPY = 'FETCH_PRICE_TEMPLATE_TO_COPY'
export const FETCH_PRICE_TEMPLATE_TO_EDIT = 'FETCH_PRICE_TEMPLATE_TO_EDIT'
export const FETCH_PRICE_TEMPLATES = 'FETCH_PRICE_TEMPLATES'
export const FETCH_PROMO_CODE_TO_COPY = 'FETCH_PROMO_CODE_TO_COPY'
export const FETCH_PROMO_CODES = 'FETCH_PROMO_CODES'
export const FETCH_PUBLISHING_PREFERENCES = 'FETCH_PUBLISHING_PREFERENCES'
export const FETCH_SERIES = 'FETCH_SERIES'
export const FETCH_SERIES_BY_ID = 'FETCH_SERIES_BY_ID'
export const FETCH_SPONSORS = 'FETCH_SPONSORS'
export const FETCH_SUITE_CARD_MODULES = 'FETCH_SUITE_CARD_MODULES'
export const FETCH_TM_IMPORT_LOGS = 'FETCH_TM_IMPORT_LOGS'
export const FETCH_VENUE_BY_ID = 'FETCH_VENUE_BY_ID'
export const FETCH_VENUES = 'FETCH_VENUES'
export const FILTER_EVENTS = 'FILTER_EVENTS'
export const FLAG_UPDATE_STATUS = 'FLAG_UPDATE_STATUS'
export const HERO_CONTENTS_UPDATE_STATUS = 'HERO_CONTENTS_UPDATE_STATUS'
export const HERO_UPDATE_STATUS = 'HERO_UPDATE_STATUS'
export const INITIALIZE_DATA_FIELD = 'INITIALIZE_DATA_FIELD'
export const INITIALIZE_SERIES = 'INITIALIZE_SERIES'
export const INVALIDATE_CACHE = 'INVALIDATE_CACHE'
export const NAVIGATION_ADD_STATUS = 'NAVIGATION_ADD_STATUS'
export const NAVIGATION_UPDATE_STATUS = 'NAVIGATION_UPDATE_STATUS'
export const NEW_SUITE_CARD_MODULE = 'NEW_SUITE_CARD_MODULE'
export const OPT_IN_UPDATE_STATUS = 'OPT_IN_UPDATE_STATUS'
export const PAGE_UPDATE_STATUS = 'PAGE_UPDATE_STATUS'
export const PROMO_CODES_UPDATE_STATUS = 'PROMO_CODES_UPDATE_STATUS'
export const PUBLISHING_PREFERENCES_UPDATE_STATUS = 'PUBLISHING_PREFERENCES_UPDATE_STATUS'
export const REMOVE_CONTENT_MODULE_BY_ID = 'REMOVE_CONTENT_MODULE_BY_ID'
export const REMOVE_EVENT_BY_ID = 'REMOVE_EVENT_BY_ID'
export const REMOVE_HERO_BY_ID = 'REMOVE_HERO_BY_ID'
export const REMOVE_ORGANISATION_BY_ID = 'REMOVE_ORGANISATION_BY_ID'
export const REMOVE_PAGE_BY_ID = 'REMOVE_PAGE_BY_ID'
export const REMOVE_SUITE_CARD_MODULE_BY_ID = 'REMOVE_SUITE_CARD_MODULE_BY_ID'
export const RESET_ACTION_STATUS = 'RESET_ACTION_STATUS'
export const RESET_ADMIN_ACTION_STATUS = 'RESET_ADMIN_ACTION_STATUS'
export const RESET_ORGANISATION_STATUS = 'RESET_ORGANISATION_STATUS'
export const SERIES_UPDATE_STATUS = 'SERIES_UPDATE_STATUS'
export const SET_USER_SESSION = 'SET_USER_SESSION'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'
export const SPONSOR_UPDATE_STATUS = 'SPONSOR_UPDATE_STATUS'
export const STORE_ARTIST_TO_EDIT = 'STORE_ARTIST_TO_EDIT'
export const STORE_CONTENT_MODULE_TO_EDIT = 'STORE_CONTENT_MODULE_TO_EDIT'
export const STORE_DATA_FIELD_TO_EDIT = 'STORE_DATA_FIELD_TO_EDIT'
export const STORE_EVENTS_BY_SERIES = 'STORE_EVENTS_BY_SERIES'
export const STORE_FLAG_TO_EDIT = 'STORE_FLAG_TO_EDIT'
export const STORE_HERO_CONTENTS_TO_EDIT = 'STORE_HERO_CONTENTS_TO_EDIT'
export const STORE_OPT_IN_TO_EDIT = 'STORE_OPT_IN_TO_EDIT'
export const STORE_PAGE_TO_EDIT = 'STORE_PAGE_TO_EDIT'
export const STORE_PRICE_TEMPLATE_EVENTS = 'STORE_PRICE_TEMPLATE_EVENTS'
export const STORE_PROMO_CODE_TO_EDIT = 'STORE_PROMO_CODE_TO_EDIT'
export const STORE_SERIES_TO_EDIT = 'STORE_SERIES_TO_EDIT'
export const STORE_SPONSOR_TO_EDIT = 'STORE_SPONSOR_TO_EDIT'
export const STORE_SUITE_CARD_MODULE_TO_EDIT = 'STORE_SUITE_CARD_MODULE_TO_EDIT'
export const STORE_TASK_RESULT = 'STORE_TASK_RESULT'
export const STORE_VENUE_TO_EDIT = 'STORE_VENUE_TO_EDIT'
export const SUITE_CARD_MODULE_UPDATE_STATUS = 'SUITE_CARD_MODULE_UPDATE_STATUS'
export const TRIGGER_MANUAL_IMPORT = 'TRIGGER_MANUAL_IMPORT'
export const UPDATE_EVENT_BY_ID = 'UPDATE_EVENT_BY_ID'
export const UPDATE_CONTENT_OPS = 'UPDATE_CONTENT_OPS'
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'
export const UPDATE_PRICE_TEMPLATE = 'UPDATE_PRICE_TEMPLATE'
export const UPDATE_PROMO_CODE = 'UPDATE_PROMO_CODE'
export const UPDATE_WORK_VIVO = 'UPDATE_WORK_VIVO'
export const VENUE_UPDATE_STATUS = 'VENUE_UPDATE_STATUS'
