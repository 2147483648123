import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { addDataField, resetDataFieldsActionStatus, initializeDataField } from '~actions'
import { DatafieldBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { Spinner, PageLayout, Switch } from '~stories'
import { getFileName } from '~utils'

import styles from './NewDataField.module.css'

const PAGE_TITLE = 'New Data Field'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewDataField = ({
  addDataField,
  initializeDataField,
  newDataFieldId,
  venues,
  updateMessage,
  updateStatus
}) => {
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [showSpinner, setShowSpinner] = useState(true)
  const [dataField, setDataField] = useState({
    description: '',
    flex_modules: [],
    title: '',
    type: '',
    venue_id: null
  })

  useEffect(() => {
    initializeDataField(accessToken)
    setShowSpinner(true)
  }, [accessToken, initializeDataField])

  useEffect(() => {
    if (!venues) return
    setShowSpinner(false)
  }, [venues])

  const handleSubmit = () => {
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
    }

    addDataField(dataField, accessToken)
  }

  const handleModalClose = () => {
    resetDataFieldsActionStatus()
  }

  const handleAddFlexModule = (event) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField
    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules,
        {
          type: 'default',
          image: {
            url: '',
            alt: ''
          },
          title: '',
          description: '',
          gallery: null,
          cta: null,
          map_src_url: null
        }
      ]
    })
  }

  const handleVenueChange = (selectedVenue) => {
    setDataField({
      ...dataField,
      venue_id: selectedVenue
    })
  }

  const handleDataTypeChange = (selectedType) => {
    setDataField({
      ...dataField,
      type: selectedType
    })
  }

  const handleFlexModuleTypeChange = (selectedType, index) => {
    const { flex_modules: flexModules = [] } = dataField

    flexModules[index] = {
      ...flexModules[index],
      type: selectedType
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleDescriptionChange = (description, index) => {
    const { flex_modules: flexModules = [] } = dataField

    flexModules[index] = {
      ...flexModules[index],
      description
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleCTALabelChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataField

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        label: event.target.value
      }
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleCTAUrlChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataField

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        url: event.target.value
      }
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleCTATargetChange = (selectedItems, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataField

    const { cta } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        ...cta,
        target: selectedItems
      }
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleMapEmbedChange = (event, dataFieldIndex) => {
    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: event.target.value
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleImageUrlChange = (secureImageURL, index) => {
    const { flex_modules: flexModules = [] } = dataField

    const alt = getFileName(secureImageURL)

    flexModules[index] = {
      ...flexModules[index],
      image: {
        alt,
        url: secureImageURL
      }
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleTitleChange = (event, index) => {
    const { flex_modules: flexModules = [] } = dataField

    flexModules[index] = {
      ...flexModules[index],
      title: event.target.value
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField

    flexModules.splice(dataFieldIndex, 1)

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModuleCTA = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: null
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModuleMapEmbedUrl = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: null
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addCTAInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      cta: {
        url: '',
        label: '',
        target: ''
      }
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const deleteFlexModuleGallery = (event, dataFieldIndex) => {
    event.stopPropagation()

    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: []
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addMapInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      map_src_url: ''
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }
  const addGalleryInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField

    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: []
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const addNewGalleryInFlexModule = (event, dataFieldIndex) => {
    event.stopPropagation()
    const { flex_modules: flexModules = [] } = dataField

    const { gallery = [] } = flexModules[dataFieldIndex]
    flexModules[dataFieldIndex] = {
      ...flexModules[dataFieldIndex],
      gallery: [
        ...gallery,
        {
          title: '',
          src: '',
          alt: '',
          mediaType: '',
          subtitle: ''
        }
      ]
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleGalleryImageAltChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataField
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        alt: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }
  const handleGalleryImageUrlUpdate = (imageUrl, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataField
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        src: imageUrl
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleGallerySubTitleChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataField
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        subtitle: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }
  const handleGalleryTitleChange = (event, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataField
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        title: event.target.value
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleFlexModuleGalleryMediaChange = (selectedItems, fieldIndex, galleryIndex) => {
    const { flex_modules: flexModules = [] } = dataField
    const { gallery = [] } = flexModules[fieldIndex]
    const updatedGallery = [
      ...gallery.slice(0, galleryIndex),
      {
        ...gallery[galleryIndex],
        mediaType: selectedItems
      },
      ...gallery.slice(galleryIndex + 1)
    ]
    flexModules[fieldIndex] = {
      ...flexModules[fieldIndex],
      gallery: updatedGallery
    }

    setDataField({
      ...dataField,
      flex_modules: [
        ...flexModules
      ]
    })
  }

  const handleTitleChange = (event) => {
    setDataField({
      ...dataField,
      title: event.target.value
    })
  }

  const handleDescriptionChange = description => {
    setDataField({
      ...dataField,
      description
    })
  }

  const deleteGalleryFlexModule = (event, dataFieldGalleryIndex, fieldIndex) => {
    event.stopPropagation()

    const clonedDataField = { ...dataField }

    if (fieldIndex !== -1) {
      const { flex_modules: flexModules } = clonedDataField
      const dField = flexModules[fieldIndex]
      const { gallery = [] } = dField

      if (dataFieldGalleryIndex !== -1) {
        gallery.splice(dataFieldGalleryIndex, 1)
      }
      clonedDataField.flex_modules[fieldIndex].gallery = [
        ...gallery
      ]
    }
    setDataField(clonedDataField)
  }

  const deleteAllGalleryFlexModule = (event, fieldIndex) => {
    event.stopPropagation()

    const clonedDataField = { ...dataField }

    if (fieldIndex !== -1) {
      if (clonedDataField.flex_modules[fieldIndex]) {
        clonedDataField.flex_modules[fieldIndex].gallery = null
      }
    }
    setDataField(clonedDataField)
  }

  const addDataFieldsButton = (
    <Button
      onClick={() => {
        resetDataFieldsActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListDataFieldsButton = (
    <Route key="listDataFields" render={({ history }) => (
      <Button
        onClick={() => {
          resetDataFieldsActionStatus()
          history.push('/datafields')
          window.location.reload()
        }}
        type="primary"
      >
        Go to Datafields List
      </Button>
    )} />
  )

  const editDataField = (
    <Route key="editFlag" render={({ history }) => (

      <Button
        onClick={() => {
          newDataFieldId && history.push(`/datafields/${newDataFieldId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const addDataFieldButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>

  const publishSwitch = <Switch
    checked={dataField.is_default || false}
    isInline={true}
    label="Is Default"
    onChange={(status) => {
      setDataField({
        ...dataField,
        is_default: status
      })
    }}
  />

  return (
    <PageLayout extra={[publishSwitch, addDataFieldButton]} title={PAGE_TITLE}>
      <div className={styles.FlagsLayoutBackground}>
        <DatafieldBasicInfo
          addCTAInFlexModule={addCTAInFlexModule}
          addGalleryInFlexModule={addGalleryInFlexModule}
          addMapInFlexModule={addMapInFlexModule}
          addNewGalleryInFlexModule={addNewGalleryInFlexModule}
          dataField={dataField}
          deleteAllGalleryFlexModule={deleteAllGalleryFlexModule}
          deleteFlexModule={deleteFlexModule}
          deleteFlexModuleCTA={deleteFlexModuleCTA}
          deleteFlexModuleGallery={deleteFlexModuleGallery}
          deleteFlexModuleMapEmbedUrl={deleteFlexModuleMapEmbedUrl}
          deleteGalleryFlexModule={deleteGalleryFlexModule}
          handleAddFlexModule={handleAddFlexModule}
          handleCTALabelChange={handleCTALabelChange}
          handleCTATargetChange={handleCTATargetChange}
          handleCTAUrlChange={handleCTAUrlChange}
          handleDFDescriptionChange={handleFlexModuleDescriptionChange}
          handleDFImageUrlUpdate={handleFlexModuleImageUrlChange}
          handleDFTitleChange={handleFlexModuleTitleChange}
          handleDataTypeChange={handleDataTypeChange}
          handleDescriptionChange={handleDescriptionChange}
          handleFlexModuleGalleryMediaChange={handleFlexModuleGalleryMediaChange}
          handleFlexModuleTypeChange={handleFlexModuleTypeChange}
          handleGalleryImageAltChange={handleGalleryImageAltChange}
          handleGalleryImageUrlUpdate={handleGalleryImageUrlUpdate}
          handleGallerySubTitleChange={handleGallerySubTitleChange}
          handleGalleryTitleChange={handleGalleryTitleChange}
          handleMapEmbedChange={handleMapEmbedChange}
          handleTitleChange={handleTitleChange}
          handleVenueChange={handleVenueChange}
          venues={venues}
        />
      </div>
      {
        updateStatus &&
        <ResultModal
          actions={[
            editDataField,
            goToListDataFieldsButton,
            addDataFieldsButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
      <Spinner
        isLoading={showSpinner}
        size="large"
      />
    </PageLayout>
  )
}

const mapStateToProps = ({ dataFields }) => ({
  newDataFieldId: dataFields.newDataFieldId,
  updateMessage: dataFields.message,
  updateStatus: dataFields.success,
  venues: dataFields.venues
})

const mapDispatchToProps = {
  addDataField,
  initializeDataField
}

NewDataField.propTypes = {
  addDataField: PropTypes.func,
  initializeDataField: PropTypes.func,
  newDataFieldId: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool,
  venues: PropTypes.array
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDataField)
