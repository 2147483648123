/* eslint-disable camelcase */
import React from 'react'
import { Card, Typography, Input, Row, Col } from 'antd'
import PropTypes from 'prop-types'

import { RichTextContentEditor } from '~components'
import { Checkbox } from '~stories'
import styles from './PageMetaInfo.module.css'

const PageMetaInfoWithEditor = ({
  handleDescriptionChange,
  handleHeadingChange,
  handleKeywordsChange,
  handleTextChange,
  handleTitleChange,
  handleShowSEOChange,
  pageMeta
}) => {
  const {
    title,
    description,
    keywords,
    should_show_seo: showSEO,
    heading,
    text
  } = pageMeta
  const { Title, Paragraph } = Typography
  return (
    <div className={styles['meta-info-container']}>
      <Card className={ styles['card-container'] }>
        <Row align={'middle'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Title</Title></Col>
        </Row>
        <Row>
          <Col>
            <Input label='' onChange={handleTitleChange} style={{ width: '1200px' }} value={title} />
          </Col>
        </Row>
        <Paragraph />
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'middle'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Description</Title></Col>
        </Row>
        <Row>
          <Col span={24}>
            <RichTextContentEditor
              onChange={(description) => handleDescriptionChange(description)}
              value={description ?? ''}
            />
          </Col>
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'middle'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Heading</Title></Col>
        </Row>
        <Row>
          <Col>
            <Input onChange={handleHeadingChange} style={{ width: '1200px' }} value={heading} />
          </Col>
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'middle'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Text</Title></Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <RichTextContentEditor
            onChange={(text) => handleTextChange(text)}
            value={text ?? ''}
          />
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'bottom'} gutter={16}>
          <Col span={1.5}><Title level={3}>Show SEO</Title></Col>
          <Col><Checkbox
            checked={showSEO}
            onChange={handleShowSEOChange}
          />
          </Col>
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'middle'} gutter={16} justify={'start'}>
          <Col span={1.5}><Title level={3}>Keywords</Title></Col>
        </Row>
        <Row gutter={16}>
          <Input onChange={handleKeywordsChange} style={{ width: '1200px' }} value={keywords} />
        </Row>
      </Card>
    </div>
  )
}

PageMetaInfoWithEditor.propTypes = {
  handleDescriptionChange: PropTypes.func,
  handleHeadingChange: PropTypes.func,
  handleKeywordsChange: PropTypes.func,
  handleShowSEOChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  pageMeta: PropTypes.object.isRequired
}

PageMetaInfoWithEditor.defaultProps = {
  pageMeta: {}
}

export default PageMetaInfoWithEditor
