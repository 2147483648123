import {
  ADD_NEW_DATA_FIELD,
  FETCH_DATA_FIELDS,
  DATA_FIELD_UPDATE_STATUS,
  STORE_DATA_FIELD_TO_EDIT,
  INITIALIZE_DATA_FIELD
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_DATA_FIELD:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_DATA_FIELDS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_DATA_FIELD_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case DATA_FIELD_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case INITIALIZE_DATA_FIELD:
      return {
        ...state,
        venues: action.payload.venues
      }
    default:
      return state
  }
}
