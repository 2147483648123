import 'isomorphic-fetch'

import { dataFieldEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

import {
  ADD_NEW_DATA_FIELD,
  FETCH_DATA_FIELDS,
  DATA_FIELD_UPDATE_STATUS,
  INITIALIZE_DATA_FIELD,
  STORE_DATA_FIELD_TO_EDIT
} from '../actionTypes'

export const fetchDataFieldsList = params => async dispatch => {
  const { method, url } = dataFieldEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { dataFields: rows, dataFieldsCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_DATA_FIELDS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchDataFieldById = dataFieldObjectId => async dispatch => {
  try {
    const { method, url } = dataFieldEndpoint.edit(dataFieldObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_DATA_FIELD_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateDataField = (dataFieldObjectId, dataField, accessToken) => async dispatch => {
  try {
    const { method, url } = dataFieldEndpoint.update(dataFieldObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(dataField),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: DATA_FIELD_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetUpdateDataFieldStatus = () => async dispatch => {
  dispatch({
    type: DATA_FIELD_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const addDataField = (dataField, accessToken) => async dispatch => {
  try {
    const { method, url } = dataFieldEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ dataField }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedDataField: { _id: newDataFieldId }, message, success } = response
        dispatch({
          type: ADD_NEW_DATA_FIELD,
          payload: { newDataFieldId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const resetDataFieldsActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_DATA_FIELD,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const initializeDataField = accessToken => async dispatch => {
  const { method, url } = dataFieldEndpoint.new
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { allVenues } = apiResponse
  dispatch({
    type: INITIALIZE_DATA_FIELD,
    payload: {
      venues: allVenues
    }
  })
}
