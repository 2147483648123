import React from 'react'
import { Card, Typography, Input, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { RichTextContentEditor, ImageUploaderToS3 } from '~components'

import styles from './EventDetail.module.css'

const EventDetails = ({ eventDetail, tmDescription, handleEventDetailNotificationChange, handleEventDetailHeaderChange, handleEventDetailDescriptionChange, handleEventDetailIconUpdate }) => {
  const { Title, Paragraph } = Typography
  const { TextArea } = Input

  return (
    <div className={styles['event-details-container']}>
      <Title className={styles['title-text']} level={2}>Event Details </Title>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Long Description*</Title></Col>
          <Col><Title level={4}>(3000 characters max; input event details , such as performer/team summary, opening act, event activations, etc.)</Title></Col>
        </Row>
        <Row style={{ minHeight: 120, height: '100%', width: '100%' }}>
          <RichTextContentEditor
            onChange={handleEventDetailDescriptionChange}
            value={tmDescription || ''}
          />
        </Row>
        {!eventDetail?.description && <Paragraph className={styles['paragraph-text']}>Please fill out this field</Paragraph>}
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Notification Header</Title></Col>
          <Col><Title level={4}> (155 characters max; add info for special notices, event policies, reschedule info, etc.)</Title></Col>
        </Row>
        <Row>
          <TextArea
            autoSize={{ minRows: 2 }}
            maxLength={155}
            onChange={handleEventDetailHeaderChange}
            style={{ width: '100%' }}
            value={eventDetail?.title}
          />
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Notification Icon*</Title></Col>
          <Col><Title level={4}> (Upload PNG or JPG; preferred dimensions 0px x 0px; max file size 1MB)</Title></Col>
        </Row>
        <Row>
          <div className={styles['action-container']}>
            <ImageUploaderToS3 handleUpload={handleEventDetailIconUpdate} logoUrl={eventDetail?.icon?.url} />
          </div>
        </Row>
      </Card>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col><Title level={3}>Event Notification Details</Title></Col>
          <Col><Title level={4}> (3000 characters max; add info for special notices, event policies, rescheduled info, etc.)</Title></Col>
        </Row>
        <Row>
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            maxLength={3000}
            onChange={handleEventDetailNotificationChange}
            value={eventDetail?.description}
          />
        </Row>
      </Card>
    </div>
  )
}

EventDetails.propTypes = {
  eventDetail: PropTypes.object,
  handleEventDetailDescriptionChange: PropTypes.func,
  handleEventDetailHeaderChange: PropTypes.func,
  handleEventDetailIconUpdate: PropTypes.func,
  handleEventDetailNotificationChange: PropTypes.func,
  tmDescription: PropTypes.string
}

export default EventDetails
