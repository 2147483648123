import 'isomorphic-fetch'

import { contentOpsEndpoint } from '~apiConfig'
import { serializerContentOps } from '~serializers'
import { getApiResponseInJSON, getIdToken } from '~utils'

import { FETCH_CONTENT_OPS_BY_ID, UPDATE_CONTENT_OPS } from '../actionTypes'

export const fetchContentOpsById = eventObjectId => async dispatch => {
  const { method, url } = contentOpsEndpoint.byId(eventObjectId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  dispatch({
    type: FETCH_CONTENT_OPS_BY_ID,
    payload: {
      ...apiResponse
    }
  })
}

export const updateContentOpsById = (eventId, data, accessToken) => async dispatch => {
  const { method, url } = contentOpsEndpoint.update(eventId)
  const event = serializerContentOps(data)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    },
    body: JSON.stringify(event)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message, success } = apiResponse
  dispatch({
    type: UPDATE_CONTENT_OPS,
    payload: { message, success }
  })
}
