import React from 'react'

import { Switch as AntDSwitch } from 'antd'
import PropTypes from 'prop-types'

import Label from '../Label/Label'
import styles from './Switch.module.css'

const CustomSwitch = ({
  checked,
  disabled,
  isInline,
  label,
  labelClassName,
  onChange
}) => {
  const className = isInline ? styles['inline-custom-switch-button'] : styles['custom-switch-button']
  return (
    <>
      <Label
        className={labelClassName}
        isInline={isInline}
        value={label}
      />
      <AntDSwitch
        checked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    </>
  )
}

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isInline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

CustomSwitch.defaultProps = {
  checked: false,
  labelClassName: '',
  disabled: false,
  isInline: false
}

export default CustomSwitch
