import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import admin from './admin/reducers'
import artists from './artists/reducers'
import auth from './auth/reducers'
import contentModules from './contentModules/reducers'
import contentOps from './contentOps/reducers'
import dashboard from './dashboard/reducers'
import dataFields from './datafields/reducers'
import events from './events/reducers'
import flags from './flags/reducers'
import heroContents from './heroContents/reducers'
import marquee from './marquee/reducers'
import navigations from './navigations/reducers'
import optInModal from './optInModal/reducers'
import organizations from './organizations/reducers'
import pages from './pages/reducers'
import priceTemplates from './priceTemplates/reducers'
import promoCodes from './promocodes/reducers'
import publishingPreferences from './publishingPreferences/reducers'
import series from './series/reducers'
import sponsors from './sponsors/reducers'
import suiteCards from './suiteCards/reducers'
import tmImportLogs from './tmImportLogs/reducers'
import venues from './venues/reducers'

export default (history) => combineReducers({
  admin,
  artists,
  auth,
  contentModules,
  contentOps,
  dataFields,
  dashboard,
  events,
  flags,
  heroContents,
  marquee,
  navigations,
  optInModal,
  organizations,
  pages,
  priceTemplates,
  promoCodes,
  publishingPreferences,
  router: connectRouter(history),
  series,
  sponsors,
  suiteCards,
  tmImportLogs,
  venues
})
