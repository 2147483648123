import React, { useState, useEffect, useRef } from 'react'
import { Card, Typography, Input, Tag, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import styles from './InternalSearchOptimization.module.css'

const InternalSearchOptimization = ({ tags, setTags }) => {
  const { Title, Paragraph } = Typography
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState(null)
  const isInitialRender = useRef(true)
  useEffect(() => {
    if (tags.length < 1 && !isInitialRender.current) {
      setError(true)
    }
  }, [tags])
  const handleChange = ({ target }) => {
    isInitialRender.current = false
    setError(false)
    setInputValue(target.value)
  }
  const handleTagClose = (tag) => {
    const newTags = tags.filter((t) => t !== tag)
    setTags(newTags)
  }
  const handleBlur = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue])
      setInputValue('')
    }
  }

  const handleEnter = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue])
      setInputValue('')
    }
  }
  return (
    <div className={styles['internal-search-optimization-container']}>
      <Title className={styles['title-text']} level={2}>Internal Search Optimization </Title>
      <Card className={ styles['card-container'] }>
        <Row align={'left'} className={styles['title-wrapper']}>
          <Col span={5}><Title level={3}>Internal Search Queries* </Title></Col>
          <Col span={18}><Title level={4}>(Input comma-separated list of queries that will help surface page within internal search engine on msg.com; consider using queries describing page content, synonyms, and more) </Title></Col>
        </Row>
        <Row className={(error === true) && styles['tag-error']} style={{ position: 'relative' }}>
          <Input
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyUp={handleEnter}
            style={{ width: '100%' }}
            value={inputValue}
          />
          <div className={styles['tag-container']}>
            {tags?.map((i) =>
              <div key={i}>
                <Tag
                  closable
                  color={'#63677D'}
                  onClose={() => handleTagClose(i)}
                >{i}</Tag>
              </div>
            )}
          </div>
        </Row>
        {(error === true) && <Paragraph className={styles['paragraph-text']}>Please fill out this field</Paragraph> }
      </Card>
    </div>

  )
}
InternalSearchOptimization.propTypes = {
  setTags: PropTypes.func,
  tags: PropTypes.array
}

export default InternalSearchOptimization
