import React, { useState } from 'react'
import { PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Image, Row, Space, Spin, Upload, notification } from 'antd'
import PropTypes from 'prop-types'
import { fetchPresignedUrl, uploadFileToS3WithPresignedUrl } from '~actions'

import styles from './ImageUploaderToS3.module.css'

const { Dragger } = Upload

const ImageUploaderToS3 = ({
  handleUpload,
  logoUrl
}) => {
  const [uploadStatus, setUploadStatus] = useState(false)
  const [fileList, setFileList] = useState([])

  const fileToS3 = async (file) => {
    if (fileList.length > 1) {
      notification.warning({ message: 'You can only upload one file!' })
      return
    }
    const isImage = file.type.startsWith('image/')
    const isSVG = file.type === 'image/svg+xml'
    if (!isImage && !isSVG) {
      notification.warning({ message: 'You can only upload image files (JPG, PNG, JPEG, SVG)' })
    }
    try {
      setUploadStatus(true)
      const fileName = file?.name.split('.')
      const formattedFile = `${fileName[0]}_${new Date().getTime()}.${fileName[1]}`
      const response = await fetchPresignedUrl({
        fileName: `event-engine/${formattedFile}`,
        fileType: file.type
      })
      const { presignedPutUrl, url } = response
      await uploadFileToS3WithPresignedUrl(presignedPutUrl, file)
      setUploadStatus(false)
      setFileList([file])
      return url
    } catch (error) {
      console.error(error)
      setUploadStatus(false)
    }
  }
  const handleChange = async ({ file }) => {
    const url = await fileToS3(file)
    handleUpload(url)
  }

  const uploadFileToS3 = async ({ file, onSuccess, onError }) => {
    const url = await fileToS3(file)
    handleUpload(url)
  }

  const handleDeleteClick = (event) => {
    setFileList([])
    handleUpload('')
    event.preventDefault()
  }

  return (<Space className={styles.mainContainer} direction='vertical' size={12}>{uploadStatus && <Spin indicator={<LoadingOutlined spin />} size="large" />}
    { logoUrl &&
      <Row>
        <Space style={{ justifyContent: 'space-between', gap: 16, display: 'flex', alignItems: 'flex-end' }}>
          <Col>
            <Image
              src={logoUrl}
              style={{ maxHeight: 'unset', height: 'auto' }}
            />
          </Col>
          <Space className={styles.container}>
            <Upload accept='image/*' customRequest={uploadFileToS3} maxCount={1} showUploadList={false}>
              <Button style={{ background: '#0078D4', borderColor: '#0078D4', fontSize: 14 }} type="primary">Update</Button>
            </Upload>
            <Button className="cloudinary-delete" onClick={handleDeleteClick} style={{ background: '#DA0D50', borderColor: '#DA0D50', fontSize: 14 }} type="primary">Delete</Button>
          </Space>
        </Space>
      </Row>
    }
    {!logoUrl && <Dragger
      accept='image/*,image/svg+xml'
      beforeUpload={() => false}
      fileList={fileList}
      maxCount={1}
      onChange={handleChange}
      onRemove={() => setFileList([])}
    >
      <Row align={'middle'} gutter={[20, 20]} justify={'center'}>
        <div className={ styles['add-icon'] } style={ { display: 'flex', padding: '10px 16px', height: '144px', width: '285px', justifyContent: 'center', alignItems: 'center' } }>
          <PlusCircleOutlined style={{ fontSize: '40px', color: '#555555' }} />
        </div>
      </Row>
    </Dragger>}
  </Space>
  )
}

ImageUploaderToS3.propTypes = {
  handleUpload: PropTypes.func,
  logoUrl: PropTypes.string
}

export default ImageUploaderToS3
