import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { fetchPageById, newSuiteCardModule, resetUpdatePageStatus, updatePage } from '~actions'
import { PageBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout, Spinner } from '~stories'
import { getFileName } from '~utils'
import { validatePage } from '~validators'

import { PAGE_TYPES } from '../../constants'
import styles from './EditPage.module.css'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const CONTENT_MODULE = {
  cta: {
    name: '',
    url: ''
  },
  description: '',
  header: '',
  icon: ''
}

const SUITE_CARD_MODULE = {
  title: '',
  subtitle: '',
  label: '',
  type: '',
  image: {},
  ctas: [],
  related_events: [],
  promotedEvents: [],
  filters: {}
}

const EditPage = ({
  allSuiteLevels,
  availableEventsForPromotion,
  fetchPage,
  newSuiteCardModule,
  page,
  resetUpdatePageStatus,
  route,
  suitesAmenities,
  suitesCapacity,
  suitesEventIdeas,
  suitesEventType,
  suitesHospitality,
  suitesSize,
  updateMessage,
  updatePage,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [pageId, setPageId] = useState('')
  const [pageToEdit, setPageToEdit] = useState({})
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()

  useEffect(() => {
    newSuiteCardModule(accessToken)
  }, [accessToken, newSuiteCardModule])

  useEffect(() => {
    const pageId = idx(route, _ => _.match.params.id)
    if (pageId) {
      setIsDataLoaded(false)
      fetchPage(pageId)
      setPageId(pageId)
    }
  }, [route, fetchPage])

  useEffect(() => {
    if (page) {
      setPageToEdit(page)
      setIsDataLoaded(true)
    }
  }, [page])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdatePageStatus()
    }
  }, [updateStatus, updateMessage, resetUpdatePageStatus])

  const handleSubmit = () => {
    const { isValid, message } = validatePage(pageToEdit)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    const updatedPage = {
      ...pageToEdit
    }
    updatePage(pageId, updatedPage, accessToken)
  }

  const keepEditingPage = (
    <Route key="editPages" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdatePageStatus()
          history.push(`/pages/${pageId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListPagesButton = (
    <Route key="listPages" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdatePageStatus()
          history.push('/pages')
        }}
        type="primary"
      >
        Go to Page List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdatePageStatus()
  }

  const handlePageNameChange = event => {
    const name = event.target.value
    setPageToEdit({
      ...pageToEdit,
      name
    })
  }

  const handleSlugChange = event => {
    const slug = event.target.value
    setPageToEdit({
      ...pageToEdit,
      slug
    })
  }

  const addContentModule = (event) => {
    event.stopPropagation()
    const { content_modules: contentModules = [] } = pageToEdit
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...contentModules,
        CONTENT_MODULE
      ]
    })
  }

  const addSuitesCardModule = (event) => {
    event.stopPropagation()
    const { cards: suiteCards = [] } = pageToEdit
    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCards,
        {
          suitesAllEvents: availableEventsForPromotion,
          related_events: [],
          promotedEvents: [],
          ...SUITE_CARD_MODULE
        }
      ]
    })
  }

  const handleContentModuleHeaderChange = (event, index) => {
    const header = event.target.value
    const { content_modules: contentModules = [] } = pageToEdit
    contentModules[index].header = header
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...contentModules
      ]
    })
  }

  const handleContentModuleContentChange = (paragraph, index) => {
    const { content_modules: contentModules = [] } = pageToEdit
    if (paragraph.length < 156) {
      contentModules[index].description = paragraph
      setPageToEdit({
        ...pageToEdit,
        content_modules: [
          ...contentModules
        ]
      })
    }
  }

  const handleContentModuleImageUrlUpdate = (secureImageURL, index) => {
    const { content_modules: contentModules = [] } = pageToEdit
    contentModules[index].icon = secureImageURL
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...contentModules
      ]
    })
  }

  const handleContentModuleCTAUrlChange = (event, index) => {
    const { content_modules: contentModules = [] } = pageToEdit
    contentModules[index] = {
      ...contentModules[index],
      cta: {
        ...contentModules[index].cta,
        url: event.target.value
      }
    }
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...contentModules
      ]
    })
  }

  const handleContentModuleCTANameChange = (event, index) => {
    const { content_modules: contentModules = [] } = pageToEdit
    contentModules[index] = {
      ...contentModules[index],
      cta: {
        ...contentModules[index].cta,
        name: event.target.value
      }
    }
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...contentModules
      ]
    })
  }

  const deleteContentModule = (event, index) => {
    event.stopPropagation()
    const { content_modules: contentModules = [] } = pageToEdit
    const updatedContentModules = contentModules.filter((_, i) => i !== index)
    setPageToEdit({
      ...pageToEdit,
      content_modules: [
        ...updatedContentModules
      ]
    })
  }

  const deleteSuitesCardModule = (event, index) => {
    event.stopPropagation()
    const { cards: suiteCards = [] } = pageToEdit
    const updatedCards = suiteCards.filter((_, i) => i !== index)
    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...updatedCards
      ]
    })
  }

  const handleSuiteAmenitiesChange = (amenities, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]
    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            amenities
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteCapacityChange = (capacity, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            capacity
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteEventIdeasChange = (eventideas, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            eventideas
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleAddCta = (event, index) => {
    event.stopPropagation()
    const { cards = [] } = pageToEdit
    const updatedCards = [...cards]
    updatedCards[index].ctas = [
      ...updatedCards[index].ctas,
      {
        title: '',
        href: ''
      }
    ]
    setPageToEdit({
      ...pageToEdit,
      cards: updatedCards
    })
  }

  const handleDeleteCta = (event, ctaIndex, suiteIndex) => {
    event.stopPropagation()
    const { cards = [] } = pageToEdit
    const updatedCards = [...cards]
    const clonedCTAs = updatedCards[suiteIndex].ctas

    if (ctaIndex !== -1) {
      clonedCTAs.splice(ctaIndex, 1)
    }
    setPageToEdit({
      ...pageToEdit,
      cards: updatedCards
    })
  }

  const handleSuiteEventTypeChange = (eventtype, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            eventtype
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteHospitalityChange = (hospitalitytype, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            hospitalitytype
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteSizeChange = (size, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const { filters } = suiteCard[index]

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          filters: {
            ...filters,
            size
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteLogoUpload = (secureImageURL, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const alt = getFileName(secureImageURL)

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          image: {
            alt,
            src: secureImageURL
          }
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteCardTypeChange = (type, index) => {
    const { cards: suiteCard = [] } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          type,
          suitesAllEvents: availableEventsForPromotion,
          related_events: [],
          promotedEvents: []
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteCardShortNameChange = (shortDesc, index) => {
    const { cards: suiteCard = [] } = pageToEdit
    const [filteredAllEvents] = allSuiteLevels.filter(suite => suite.type === shortDesc)
    const allEventItems = shortDesc ? filteredAllEvents?.allEvents : availableEventsForPromotion
    const allEventsFiltered = shortDesc ? filteredAllEvents?.events : []
    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          short_desc: shortDesc || '',
          suitesAllEvents: allEventItems,
          related_events: allEventsFiltered.map(({ id }) => id),
          promotedEvents: allEventsFiltered
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteSubTitleChange = (event, index) => {
    const { cards: suiteCard = [] } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          subtitle: event.target.value
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteCTAUpdate = (ctas, index) => {
    const { cards: suiteCard = [] } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          subtitle: event.target.value
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteFormStatus = (event, index) => {
    const { cards: suiteCard = [] } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          show_form: event.target.checked
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleSuiteTitleChange = (event, index) => {
    const { cards: suiteCard = [] } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      cards: [
        ...suiteCard.slice(0, index),
        {
          ...suiteCard[index],
          title: event.target.value
        },
        ...suiteCard.slice(index + 1)
      ]
    })
  }

  const handleHeroTitleChange = event => {
    const title = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        title
      }
    })
  }

  const handleHeroButtonLabelChange = event => {
    const buttonName = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        button_name: buttonName
      }
    })
  }
  const handleHeroPhoneNumberChange = event => {
    const phoneNumber = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        phone_number: phoneNumber
      }
    })
  }

  const handleHeroDescriptionChange = description => {
    const { hero } = pageToEdit

    if (description.length < 156) {
      setPageToEdit((pageToEdit) => ({
        ...pageToEdit,
        hero: {
          ...hero,
          description
        }
      }))
    }
  }
  const handleHeroSubTitleChange = event => {
    const subTitle = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        sub_title: subTitle
      }
    })
  }
  const handleHeroImageUrlUpdate = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    const { hero } = pageToEdit

    const alt = getFileName(imageUrl)
    const { image } = hero
    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        image: {
          ...image,
          alt,
          src: imageUrl
        }
      }
    })
  }
  const handleHeroChatLabelChange = event => {
    const chatButton = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        chat_button: chatButton
      }
    })
  }

  const handleHeroEmailChange = event => {
    const email = event.target.value
    const { hero } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      hero: {
        ...hero,
        email
      }
    })
  }

  const handlePageMetaDescriptionChange = (description) => {
    const { page_meta: pageMeta } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        description
      }
    })
  }

  const handlePageMetaHeadingChange = (event) => {
    const { page_meta: pageMeta } = pageToEdit
    const heading = event.target.value

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        heading
      }
    })
  }

  const handlePageMetaKeywordsChange = (event) => {
    const keywords = event.target.value
    const { page_meta: pageMeta } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        keywords
      }
    })
  }

  const handlePageMetaShowSEOChange = (event) => {
    const showSEO = event.target.checked
    const { page_meta: pageMeta } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        should_show_seo: showSEO
      }
    })
  }

  const handlePageMetaTextChange = (text) => {
    const { page_meta: pageMeta } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        text
      }
    })
  }

  const handlePageMetaTitleChange = (event) => {
    const title = event.target.value
    const { page_meta: pageMeta } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      page_meta: {
        ...pageMeta,
        title
      }
    })
  }

  const handleFormModuleIdUpdate = (event) => {
    const formstackId = event.target.value

    const { form } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        formstack_id: formstackId
      }
    })
  }

  const handleFormModuleDescriptionChange = (description) => {
    const { form } = pageToEdit

    if (description.length < 156) {
      setPageToEdit((pageToEdit) => ({
        ...pageToEdit,
        form: {
          ...form,
          description
        }
      }))
    }
  }

  const handleFormModuleHeaderChange = (header) => {
    const { form } = pageToEdit

    if (header.length < 71) {
      setPageToEdit((pageToEdit) => ({
        ...pageToEdit,
        form: {
          ...form,
          header
        }
      }))
    }
  }

  const handleFormModuleDisclaimerChange = (disclaimer) => {
    const { form } = pageToEdit

    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        disclaimer
      }
    })
  }

  const handleButtonTextUpdate = (event) => {
    const submitButtonText = event.target.value
    const { form } = pageToEdit
    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        submit_button_text: submitButtonText
      }
    })
  }

  const handleFormModuleTitleChange = (title) => {
    const { form } = pageToEdit
    const { success_screen: successScreen } = form
    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        success_screen: {
          ...successScreen,
          title: title
        }
      }
    })
  }

  const handleFormModuleMessageChange = (message) => {
    const { form } = pageToEdit
    const { success_screen: successScreen } = form
    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        success_screen: {
          ...successScreen,
          message
        }
      }
    })
  }

  const handleCTAHrefChange = (href, suiteIndex, ctaIndex) => {
    const { cards = [] } = pageToEdit
    const updatedCards = [...cards]
    const clonedCTAs = updatedCards[suiteIndex].ctas

    if (ctaIndex !== -1 && clonedCTAs[ctaIndex]) {
      clonedCTAs[ctaIndex].href = href
    }
    setPageToEdit({
      ...pageToEdit,
      cards: updatedCards
    })
  }

  const handleCTATitleChange = (title, suiteIndex, ctaIndex) => {
    const { cards = [] } = pageToEdit
    const updatedCards = [...cards]
    const clonedCTAs = updatedCards[suiteIndex].ctas

    if (ctaIndex !== -1 && clonedCTAs[ctaIndex]) {
      clonedCTAs[ctaIndex].title = title
    }
    setPageToEdit({
      ...pageToEdit,
      cards: updatedCards
    })
  }

  const handleFormModuleSuccessSubmitButtonTextUpdate = (event) => {
    const successScreenButtonText = event.target.value
    const { form } = pageToEdit
    const { success_screen: successScreen } = form
    setPageToEdit({
      ...pageToEdit,
      form: {
        ...form,
        success_screen: {
          ...successScreen,
          button_text: successScreenButtonText
        }
      }
    })
  }

  const handleAllEventsChange = (events, suiteIndex) => {
    const { cards: suiteCards = [] } = pageToEdit

    if (suiteCards[suiteIndex]) {
      suiteCards[suiteIndex].suitesAllEvents = [...events]
    }

    setPageToEdit({
      ...pageToEdit,
      cards: suiteCards
    })
  }

  const handleSelectedEventsChange = (selectedEvents, suiteIndex) => {
    const { cards: suiteCards = [] } = pageToEdit

    if (suiteCards[suiteIndex]) {
      suiteCards[suiteIndex].promotedEvents = [...selectedEvents]
      suiteCards[suiteIndex].related_events = selectedEvents.map(({ id }) => id)
    }

    setPageToEdit({
      ...pageToEdit,
      cards: suiteCards
    })
  }

  const updatePageButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>

  return (
    <PageLayout extra={[updatePageButton]} title='Update Page'>
      <div>
        <PageBasicInfo
          addContentModule={addContentModule}
          addSuitesCardModule={addSuitesCardModule}
          allSuiteLevels={allSuiteLevels}
          deleteContentModule={deleteContentModule}
          deleteSuitesCardModule={deleteSuitesCardModule}
          handleAddCta={handleAddCta}
          handleAllEventsChange={handleAllEventsChange}
          handleButtonTextUpdate={handleButtonTextUpdate}
          handleCTAHrefChange={handleCTAHrefChange}
          handleCTATitleChange={handleCTATitleChange}
          handleContentModuleCTANameChange={handleContentModuleCTANameChange}
          handleContentModuleCTAUrlChange={handleContentModuleCTAUrlChange}
          handleContentModuleContentChange={handleContentModuleContentChange}
          handleContentModuleHeaderChange={handleContentModuleHeaderChange}
          handleContentModuleImageUrlUpdate={handleContentModuleImageUrlUpdate}
          handleDeleteCta={handleDeleteCta}
          handleFormModuleDescriptionChange={handleFormModuleDescriptionChange}
          handleFormModuleDisclaimerChange={handleFormModuleDisclaimerChange}
          handleFormModuleHeaderChange={handleFormModuleHeaderChange}
          handleFormModuleIdUpdate={handleFormModuleIdUpdate}
          handleFormModuleMessageChange={handleFormModuleMessageChange}
          handleFormModuleSuccessSubmitButtonTextUpdate={handleFormModuleSuccessSubmitButtonTextUpdate}
          handleFormModuleTitleChange={handleFormModuleTitleChange}
          handleHeroButtonLabelChange={handleHeroButtonLabelChange}
          handleHeroChatLabelChange={handleHeroChatLabelChange}
          handleHeroDescriptionChange={handleHeroDescriptionChange}
          handleHeroEmailChange={handleHeroEmailChange}
          handleHeroImageUrlUpdate={handleHeroImageUrlUpdate}
          handleHeroPhoneNumberChange={handleHeroPhoneNumberChange}
          handleHeroSubTitleChange={handleHeroSubTitleChange}
          handleHeroTitleChange={handleHeroTitleChange}
          handlePageMetaDescriptionChange={handlePageMetaDescriptionChange}
          handlePageMetaHeadingChange={handlePageMetaHeadingChange}
          handlePageMetaKeywordsChange={handlePageMetaKeywordsChange}
          handlePageMetaShowSEOChange={handlePageMetaShowSEOChange}
          handlePageMetaTextChange={handlePageMetaTextChange}
          handlePageMetaTitleChange={handlePageMetaTitleChange}
          handlePageNameChange={handlePageNameChange}
          handleSelectedEventsChange={handleSelectedEventsChange}
          handleSlugChange={handleSlugChange}
          handleSuiteAmenitiesChange={handleSuiteAmenitiesChange}
          handleSuiteCTAUpdate={handleSuiteCTAUpdate}
          handleSuiteCapacityChange={handleSuiteCapacityChange}
          handleSuiteCardShortNameChange={handleSuiteCardShortNameChange}
          handleSuiteCardTypeChange={handleSuiteCardTypeChange}
          handleSuiteEventIdeasChange={handleSuiteEventIdeasChange}
          handleSuiteEventTypeChange={handleSuiteEventTypeChange}
          handleSuiteFormStatus={handleSuiteFormStatus}
          handleSuiteHospitalityChange={handleSuiteHospitalityChange}
          handleSuiteLogoUpload={handleSuiteLogoUpload}
          handleSuiteSizeChange={handleSuiteSizeChange}
          handleSuiteSubTitleChange={handleSuiteSubTitleChange}
          handleSuiteTitleChange={handleSuiteTitleChange}
          page={pageToEdit}
          pageTypes={PAGE_TYPES}
          selectedType={pageToEdit?.type}
          suitesAmenities={suitesAmenities}
          suitesCapacity={suitesCapacity}
          suitesEventIdeas={suitesEventIdeas}
          suitesEventType={suitesEventType}
          suitesHospitality={suitesHospitality}
          suitesSize={suitesSize}
        />
      </div>
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListPagesButton,
            keepEditingPage
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditPage.propTypes = {
  allSuiteLevels: PropTypes.array,
  availableEventsForPromotion: PropTypes.array,
  fetchPage: PropTypes.func,
  newSuiteCardModule: PropTypes.func,
  page: PropTypes.object,
  resetUpdatePageStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  suitesAmenities: PropTypes.array.isRequired,
  suitesCapacity: PropTypes.array.isRequired,
  suitesEventIdeas: PropTypes.array.isRequired,
  suitesEventType: PropTypes.array.isRequired,
  suitesHospitality: PropTypes.array.isRequired,
  suitesSize: PropTypes.array.isRequired,
  updateMessage: PropTypes.string,
  updatePage: PropTypes.func,
  updateStatus: PropTypes.string
}

const mapStateToProps = ({ pages, suiteCards }) => ({
  page: pages.page,
  updateStatus: pages.success,
  updateMessage: pages.message,
  suitesAmenities: suiteCards.suitesAmenities,
  suitesCapacity: suiteCards.suitesCapacity,
  suitesEventIdeas: suiteCards.suitesEventIdeas,
  suitesEventType: suiteCards.suitesEventType,
  suitesHospitality: suiteCards.suitesHospitality,
  suitesSize: suiteCards.suitesSize,
  availableEventsForPromotion: suiteCards.allEvents,
  allSuiteLevels: suiteCards.allSuiteLevels
})

const mapDispatchToProps = {
  fetchPage: fetchPageById,
  newSuiteCardModule,
  resetUpdatePageStatus,
  updatePage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPage)
