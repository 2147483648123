import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Input, List, Row, Typography } from 'antd'
import PropTypes from 'prop-types'
import 'antd/dist/antd.css'
import DownArrow from './DownArrow'
import styles from './EventRecommendations.module.css'
import UpArrow from './UpArrow'

const { Search } = Input
const { Title } = Typography

const EventRecommendations = ({ availableList, curAvailableEvent, curSelectedEvent, selectedList, setCurAvailableEvent, setCurSelectedEvent }) => {
  const [filteredAvailableEvent, setFilteredAvailableEvent] = useState([])
  const [filteredSelectedEvent, setFilteredSelectedEvent] = useState([])
  const handleOnDelete = (e, item) => {
    const list = curSelectedEvent.filter(i => {
      if (!i.title.includes(item)) return i
    })
    const selectedItem = curSelectedEvent.filter(i => {
      if (i.title.includes(item)) return i
    })
    setCurAvailableEvent([...curAvailableEvent, ...selectedItem])
    setCurSelectedEvent(list)
  }

  const handleOnSearch = (searchText) => {
    if (searchText === '') {
      setFilteredAvailableEvent([])
      setFilteredSelectedEvent([])
      return
    }
    setFilteredAvailableEvent(curAvailableEvent.filter((filterField) => {
      const filterAttribute = (filterField.title).toLowerCase()
      return searchText === '' || filterAttribute.indexOf(searchText.toLowerCase()) > -1
    }))

    setFilteredSelectedEvent(curSelectedEvent.filter((filterField) => {
      const filterAttribute = (filterField.title).toLowerCase()
      return searchText === '' || filterAttribute.indexOf(searchText.toLowerCase()) > -1
    }))
  }

  const handleSelectedEvent = (e, item) => {
    e.preventDefault()
    const list = curAvailableEvent.filter(i => {
      if (!i.title.includes(item)) return i
    })
    const selectedList = curAvailableEvent.filter(i => {
      if (i.title.includes(item)) return i
    })
    setCurAvailableEvent(list)
    setCurSelectedEvent([...curSelectedEvent, ...selectedList])
  }

  const handleMoveUp = (id) => {
    const tempList = curSelectedEvent
    const index = tempList.findIndex(e => e === id)
    if (index > 0) {
      const el = tempList[index]
      tempList[index] = tempList[index - 1]
      tempList[index - 1] = el
      setCurSelectedEvent([...tempList])
    }
  }
  const handleMoveDown = (id) => {
    const tempList = curSelectedEvent
    const index = tempList.findIndex(e => e === id)
    if (index !== -1 && index < tempList.length - 1) {
      const el = tempList[index]
      tempList[index] = tempList[index + 1]
      tempList[index + 1] = el
      setCurSelectedEvent([...tempList])
    }
  }

  const sortableItem = (item, i) => {
    return <List.Item className={styles.multiSelectListItem} >
      <Row key={`${item}-${i}`} align={'left'} style={ { width: '100%' } }>
        <Col className={styles.dragHandle} span={3}>
          {i !== 0 && <div className={styles.dragHandle} onClick={() => handleMoveUp(item)} span={1}>
            <UpArrow />
          </div>}
          {curSelectedEvent.length - i !== 1
            ? <div className={styles.dragHandle} onClick={() => handleMoveDown(item)} span={2}>
              <DownArrow />
            </div>
            : <Col className={styles.dragHandle} span={3} />}
        </Col>
        <Col className={ styles['list-container'] } span={19}>{ item }</Col>
        <Col className={ styles['list-container'] } span={2}><Button className={ styles.deleteSelectedEvent } icon={ <CloseOutlined /> } onClick={(e) => handleOnDelete(e, item) } shape="none" style={ { fontSize: '18px', color: '#555555' } }/></Col>
      </Row>
    </List.Item>
  }

  return (
    <div className={ styles.multiSelectSearchContainer }>
      <Row gutter={ [10, 10] }>
        <Search allowClear onChange={(event) => handleOnSearch(event.target.value)} onSearch={ handleOnSearch } style={{ width: 1200 }} />
      </Row>
      <Row gutter={ [30, 10] }>
        <Col span={11}>
          {curAvailableEvent && <List
            className={ styles.multiSelectList }
            dataSource={ filteredAvailableEvent.length > 0 ? filteredAvailableEvent.map(i => i.title) : curAvailableEvent.map(i => i.title) }
            header={ <Title level={3} >Available Events</Title> }
            renderItem={ (item) => <List.Item onClick={(e) => handleSelectedEvent(e, item)}>{ item }</List.Item> }
          />}
        </Col>
        <Col span={13}>
          {curSelectedEvent && <List
            className={ styles.multiSelectList }
            dataSource={filteredSelectedEvent.length > 0 ? filteredSelectedEvent.map(i => i.title) : curSelectedEvent.map(i => i.title) }
            header={ <Title level={3}>Selected Events</Title> }
            renderItem={ (item, i) => sortableItem(item, i) }
          />}
        </Col>
      </Row>
    </div>
  )
}
EventRecommendations.propTypes = {
  availableList: PropTypes.array.isRequired,
  curAvailableEvent: PropTypes.array.isRequired,
  curSelectedEvent: PropTypes.array.isRequired,
  selectedList: PropTypes.array.isRequired,
  setCurAvailableEvent: PropTypes.func.isRequired,
  setCurSelectedEvent: PropTypes.func.isRequired
}

export default EventRecommendations
