import React from 'react'
import { Button, notification } from 'antd'
import { Route } from 'react-router-dom'

import { ItemList } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}
const PAGE_TITLE = 'Data Fields'

const getDataFieldsNameWithLink = datafield => <a href={`datafields/${datafield._id}/edit`}>{String(datafield?.title).toUpperCase()}</a>

const columnData = [
  {
    dataIndex: 'title',
    render: (text, flag) => getDataFieldsNameWithLink(flag),
    sorter: true,
    title: 'Title',
    width: '30%',
    sortDirections: ['descend', 'ascend', 'descend']
  },
  {
    dataIndex: 'type',
    title: 'Type',
    width: '40%'
  },
  {
    dataIndex: 'venue_id',
    title: 'Venue',
    width: '30%'
  }
]

const addNewFlag = isContentEditor => {
  return (
    <Route render={({ history }) => (
      <Button
        key="1"
        onClick={() => {
          isContentEditor
            ? history.push('/datafields/new')
            : notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
        }}
        type="primary"
      >
        Add DataField
      </Button>
    )} />
  )
}

const DataFields = () => {
  const { userDetails: { isContentEditor } } = getCurrentUser()

  return (
    <PageLayout extra={[
      addNewFlag(isContentEditor)
    ]} title={PAGE_TITLE}>
      <div className="sponsors-layout-background">
        <ItemList columns={columnData} resource='dataFields' />
      </div>
    </PageLayout>
  )
}

export default DataFields
